import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Styles/SportsSlider.css";

function TakeClub() {

  const sports = [
    {
      name: "RUNNING",
      image: "https://brand.assets.adidas.com/image/upload/f_auto,q_auto:best,fl_lossy/if_w_gt_800,w_800/running_d_79c8219795.jpg"
    },
    {
      name: "TRAINING",
      image: "https://brand.assets.adidas.com/image/upload/f_auto,q_auto:best,fl_lossy/if_w_gt_800,w_800/training_d_a5c2eb1789.jpg"
    },
    {
      name: "FOOTBALL",
      image: "https://brand.assets.adidas.com/image/upload/f_auto,q_auto:best,fl_lossy/if_w_gt_800,w_800/football_d_6dd2ca489e.jpg"
    },
    {
      name: "TENNIS",
      image: "https://brand.assets.adidas.com/image/upload/f_auto,q_auto:best,fl_lossy/if_w_gt_800,w_800/tennis_d_84458b28fe.jpg"
    },
    {
      name: "GOLF",
      image: "https://brand.assets.adidas.com/image/upload/f_auto,q_auto:best,fl_lossy/if_w_gt_800,w_800/golf_d_8f6a5ceae9.jpg"
    }
  ];
  

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <div>
      <div className="cms-section no-overflow pos-3 cms-section-default">
        <div className="cms-section-default full-width">
          <div className="cms-block block-container-2 title-text-box pos-0 cms-block-text">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row">
                <div
                  className="col-12"
                  data-cms-element-id="2eb0409c0b874fceacad996d8f8bf32a"
                >
                  <div className="cms-element-text">
                    <div className="text-box-emotion">
                      <h3 className="t1">
                        Level Up Your Team
                        <span style={{ fontFamily: "Arial" }}>'</span>s Look
                      </h3>
                      <p className="t2">
                        Design a custom kit for your club in minutes
                        <span style={{ fontFamily: "Arial" }}>-</span>just like
                        the pros<span style={{ fontFamily: "Arial" }}>-</span>
                        using Shield Sports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="cms-block block-container-2 pos-1 cms-block-spized-youtube-video">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row">
                <div
                  className="col-12"
                  data-cms-element-id="bcfd048142174e16b298b7e45a7526ff"
                >
                  <div className="cms-element-youtube-video">
                    <iframe
                      src="https://www.youtube-nocookie.com/embed/gFpLfsJ0r3k?rel=0&autoplay=1&mute=1&disablekb=1"
                      className="cms-element-youtube-video__video"
                      allow="autoplay"
                      title="Take Your Club to the Next Level"
                      style={{ width: "100%", height: "100%", aspectRatio: "16/9", border: "none" }}
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="slider-container">
            <h2 className="slider-heading">
              SELECT YOUR SPORT, FIND YOUR GEAR, AND GET IN THE GAME!
            </h2>
            <Slider {...settings}>
              {sports.map((sport, index) => (
                <div key={index} className="slide">
                  <img
                    src={sport.image}
                    alt={sport.name}
                    className="sport-image"
                  />
                  <h3 className="sport-name">{sport.name}</h3>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TakeClub;
