import React, { useState } from "react";

function Filter() {
  const [quantity, setQuantity] = useState(10);

  const changeCount = (action) => {
    setQuantity((prevQuantity) => {
      if (action === "increase") {
        return prevQuantity + 1;
      } else if (action === "decrease" && prevQuantity > 0) {
        return prevQuantity - 1;
      }
      return prevQuantity;
    });
  };

  const calculate = () => {
    alert(`You have calculated a quantity of: ${quantity}`);
  };
  return (
    <div className="cms-section-sidebar-sidebar-content col-lg-4 col-xl-3">
      <div className="cms-block  pos-3 cms-block-sidebar-filter">
        <div className="cms-block-container">
          <div className="cms-block-container-row row cms-row has--sidebar">
            <div
              className="col-12"
              data-cms-element-id="b3cdf007173c4e159586737b2b102f11"
            >
              <div className="cms-element-sidebar-filter">
                <div className="oppened-filters"></div>
                <div
                  id="filter-panel-wrapper"
                  className="filter-panel-wrapper"
                  data-offcanvas-filter-content="true"
                >
                  <div className="filter-panel-offcanvas-header">
                    <div className="filter-panel-offcanvas-only">
                      <h3 className="filter-panel-offcanvas-title">Filter</h3>
                      <div className="filter-panel-offcanvas-close js-offcanvas-close">
                        <span className="icon icon-x icon-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <defs>
                              <path
                                d="m10.5858 12-7.293-7.2929c-.3904-.3905-.3904-1.0237 0-1.4142.3906-.3905 1.0238-.3905 1.4143 0L12 10.5858l7.2929-7.293c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L13.4142 12l7.293 7.2929c.3904.3905.3904 1.0237 0 1.4142-.3906.3905-1.0238.3905-1.4143 0L12 13.4142l-7.2929 7.293c-.3905.3904-1.0237.3904-1.4142 0-.3905-.3906-.3905-1.0238 0-1.4143L10.5858 12z"
                                id="icons-default-x"
                              />
                            </defs>
                            <use
                              href="#icons-default-x"
                              fill="#758CA3"
                              fill-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="filter-panel is--sidebar">
                    <div className="filter-panel-active-container"></div>
                    <div className="filter-panel-active-container-close js-offcanvas-close btn btn-primary">
                      <span className="f_spized-filter-total-products"></span>{" "}
                      Produkte anzeigen
                    </div>
                    <div className="filter-panel-items-container">
                      <div className="filter-count filter-count-properties filter-panel-item d-grid">
                        <div
                          className=""
                          aria-expanded="true"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-count-2017962821"
                          style={{alignItems:"center", }}
                        >
                          Show prices for
                          <span
                            className="info-opened-btn f_info-opened-btn"
                            data-bs-toggle="tooltip"
                            title="The larger the purchase quantity the lower the price per article"
                            style={{marginTop:"5px"}}
                          >
                            i
                          </span>
                          
                        </div>
                        <div className="filter-count-dropdown filter-panel-item-dropdown collapse show">
                          <div
                            className="filter-quantity-select quantity-calibration-box filter-panel-item"
                            data-filter-quantity="true"
                          >
                            <div className="select-field-increase-box">
                              <div
                                className="select-field-decrease-btn f_change-count"
                                onClick={() => changeCount("decrease")}
                              >
                                -
                              </div>
                              <input
                                type="text"
                                className="select-field-count-box count-input f_count-box"
                                value={quantity}
                                readOnly 
                              />
                              <div
                                className="select-field-increase-btn f_change-count"
                                onClick={() => changeCount("increase")}
                              >
                                +
                              </div>
                            </div>
                            <a
                              className="btn btn-primary apply-change-count"
                              onClick={calculate}
                            >
                              Calculate
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="filter-category-select filter-category-select-properties filter-panel-item d-grid">
                        <button
                          className="filter-panel-item-toggle btn"
                          aria-expanded="true"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-category-391519304"
                        >
                          Sport
                          <span className="filter-multi-select-count"></span>
                          <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <use
                                href="#icons-solid-arrow-medium-down"
                                fill="#758CA3"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                        <div
                          className="filter-category-select-dropdown filter-panel-item-dropdown collapse show"
                          id="filter-category-391519304"
                        >
                          <ul className="filter-category-select-list">
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="018b597c47d77f999d9357dbe483b5f1"
                                href="../../sports-clothing.html"
                              >
                                All sports
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link is-active"
                                href="../../football-products.html"
                              >
                                Football
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="43cfc7d554f0428a857487aaa0e0cc41"
                                href="../../handball-products.html"
                              >
                                Handball
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="8eac79589ee64501b4ace5bed092fee3"
                                href="../../running-products.html"
                              >
                                Running
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="c5052e6c6e074fbdaa806bb3f9e2095b"
                                href="../../../esports-products.html"
                              >
                                eSports
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="f6136c6d25014710a84cdcbfb4b8818e"
                                href="../../cycling-products.html"
                              >
                                Cycling
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="bd21b183330a47ddabcfac29b09522e1"
                                href="../../basketball-products.html"
                              >
                                Basketball
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="d6f0faffa62b4a4994ff5bf8e9f24763"
                                href="../../wintersports-products.html"
                              >
                                Winter sports
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="44ee7a4dce3a4a408fd18ce103acc659"
                                href="../../badminton-products.html"
                              >
                                Badminton
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="7300694a5bb2471bb3ee16e1c023f652"
                                href="../../volleyball-products.html"
                              >
                                Volleyball
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="c1fbf115d0d04fe79028d1aec9928e13"
                                href="../../darts-products.html"
                              >
                                Darts
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="2f359b7662154612af4ce8add2a1240c"
                                href="../../table-tennis-products.html"
                              >
                                Table tennis
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="93476fbca28b40489c38b19295b9c02c"
                                href="../../floorball-products.html"
                              >
                                Floorball
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="9b4fdb13da2345f6b8bbbc2d35800732"
                                href="../../bowling-products.html"
                              >
                                Bowling
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="08bc4217099945b1946f2f5763b65e7a"
                                href="../../rugby-products.html"
                              >
                                Rugby
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="ea592cf167124432bf9b7855f810cbdf"
                                href="../../fieldhockey-products.html"
                              >
                                Field hockey
                              </a>
                            </li>
                            <li
                              className="filter-category-select-list-item"
                              data-title-position
                            >
                              <a
                                className="category-navigation-link"
                                data-id="1d9c02d1b7a34984a0ef0395bdebb8f8"
                                href="../../fitness-products.html"
                              >
                                Fitness
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
                        data-filter-property-select="true"
                        data-filter-property-select-options='{"name":"properties","propertyName":"Gender","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
                      >
                        <button
                          className="filter-panel-item-toggle btn"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-properties-791505717"
                        >
                          Gender
                          <span className="filter-multi-select-count"></span>
                          <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <use
                                href="#icons-solid-arrow-medium-down"
                                fill="#758CA3"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                        <div
                          className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
                          id="filter-properties-791505717"
                        >
                          <ul
                            className="filter-multi-select-list"
                            data-id="9363bb6904ee47789c42f17c55b9be8d"
                            data-title-prefix
                            data-title-postfix
                            data-title-position="4"
                            data-url-position="4"
                            data-related-property-groups="{}"
                            data-hide-from-title-on-related-property-groups="{}"
                            data-hide-category-from-title="0"
                          >
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="906ca600c487432390a32e2cd3a98312"
                              data-url-name="kids"
                              data-title-name="for kids"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Kids"
                                    value="906ca600c487432390a32e2cd3a98312"
                                    id="906ca600c487432390a32e2cd3a98312"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="906ca600c487432390a32e2cd3a98312"
                                  >
                                    <span className="text-block">Kids</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="0e787a9b071b4404bcc76304c17cab3b"
                              data-url-name="men"
                              data-title-name="for men"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Men"
                                    value="0e787a9b071b4404bcc76304c17cab3b"
                                    id="0e787a9b071b4404bcc76304c17cab3b"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="0e787a9b071b4404bcc76304c17cab3b"
                                  >
                                    <span className="text-block">Men</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="515f05ec1d6a4015b7c7fbe82da6f89c"
                              data-url-name="unisex"
                              data-title-name="Unisex"
                              data-use-as-prefix="1"
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Unisex"
                                    value="515f05ec1d6a4015b7c7fbe82da6f89c"
                                    id="515f05ec1d6a4015b7c7fbe82da6f89c"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="515f05ec1d6a4015b7c7fbe82da6f89c"
                                  >
                                    <span className="text-block">Unisex</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="9afcecef9d3e4fe0b25b5143a9126e42"
                              data-url-name="women"
                              data-title-name="for women"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Women"
                                    value="9afcecef9d3e4fe0b25b5143a9126e42"
                                    id="9afcecef9d3e4fe0b25b5143a9126e42"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="9afcecef9d3e4fe0b25b5143a9126e42"
                                  >
                                    <span className="text-block">Women</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
                        data-filter-property-select="true"
                        data-filter-property-select-options='{"name":"properties","propertyName":"Product category","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
                      >
                        <button
                          className="filter-panel-item-toggle btn"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-properties-1461025610"
                        >
                          Product category
                          <span className="filter-multi-select-count"></span>
                          <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <use
                                href="#icons-solid-arrow-medium-down"
                                fill="#758CA3"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                        <div
                          className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
                          id="filter-properties-1461025610"
                        >
                          <ul
                            className="filter-multi-select-list"
                            data-id="018ea94198d97fc78914e87756acdb9f"
                            data-title-prefix
                            data-title-postfix
                            data-title-position="3"
                            data-url-position="3"
                            data-related-property-groups="{}"
                            data-hide-from-title-on-related-property-groups='["018ea944b6d079d5831860265a2d4c4d"]'
                            data-hide-category-from-title="0"
                          >
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="019078cea2277da1b1c30d93cc3302f2"
                              data-url-name="fanscarfs"
                              data-title-name="fanscarfs"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Fanscarfs"
                                    value="019078cea2277da1b1c30d93cc3302f2"
                                    id="019078cea2277da1b1c30d93cc3302f2"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="019078cea2277da1b1c30d93cc3302f2"
                                  >
                                    <span className="text-block">Fanscarfs</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea94323167c63b9e7a83d7ed29c01"
                              data-url-name="headgear"
                              data-title-name="headgear"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Headgear"
                                    value="018ea94323167c63b9e7a83d7ed29c01"
                                    id="018ea94323167c63b9e7a83d7ed29c01"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea94323167c63b9e7a83d7ed29c01"
                                  >
                                    <span className="text-block">Headgear</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea942f72f7f7e9d3d9da3f336434b"
                              data-url-name="jackets"
                              data-title-name="jackets"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Jackets"
                                    value="018ea942f72f7f7e9d3d9da3f336434b"
                                    id="018ea942f72f7f7e9d3d9da3f336434b"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea942f72f7f7e9d3d9da3f336434b"
                                  >
                                    <span className="text-block">Jackets</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea942ab777921a1b60714142ac22f"
                              data-url-name="jerseys"
                              data-title-name="jerseys"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix=" "
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Jerseys"
                                    value="018ea942ab777921a1b60714142ac22f"
                                    id="018ea942ab777921a1b60714142ac22f"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea942ab777921a1b60714142ac22f"
                                  >
                                    <span className="text-block">Jerseys</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea943d2107e69bbe4291bbe07dd55"
                              data-url-name="socks"
                              data-title-name="socks"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Socks"
                                    value="018ea943d2107e69bbe4291bbe07dd55"
                                    id="018ea943d2107e69bbe4291bbe07dd55"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea943d2107e69bbe4291bbe07dd55"
                                  >
                                    <span className="text-block">Socks</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea9430cff7590a08d6246b1321503"
                              data-url-name="sweatshirt"
                              data-title-name="sweatshirts"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Sweatshirts"
                                    value="018ea9430cff7590a08d6246b1321503"
                                    id="018ea9430cff7590a08d6246b1321503"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea9430cff7590a08d6246b1321503"
                                  >
                                    <span className="text-block">Sweatshirts</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea942e12e7e818c8cce9feae7e6f8"
                              data-url-name="tracksuits"
                              data-title-name="tracksuits"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Tracksuits"
                                    value="018ea942e12e7e818c8cce9feae7e6f8"
                                    id="018ea942e12e7e818c8cce9feae7e6f8"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea942e12e7e818c8cce9feae7e6f8"
                                  >
                                    <span className="text-block">Tracksuits</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea942c8a7712c8217948d03b0326e"
                              data-url-name="trousers"
                              data-title-name="trousers"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Trousers"
                                    value="018ea942c8a7712c8217948d03b0326e"
                                    id="018ea942c8a7712c8217948d03b0326e"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea942c8a7712c8217948d03b0326e"
                                  >
                                    <span className="text-block">Trousers</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="019097d43ff37a06a0ec8571bc70efdb"
                              data-url-name="underwear"
                              data-title-name="Underwear"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Underwear"
                                    value="019097d43ff37a06a0ec8571bc70efdb"
                                    id="019097d43ff37a06a0ec8571bc70efdb"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="019097d43ff37a06a0ec8571bc70efdb"
                                  >
                                    <span className="text-block">Underwear</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
                        data-filter-property-select="true"
                        data-filter-property-select-options='{"name":"properties","propertyName":"Product variant","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
                      >
                        <button
                          className="filter-panel-item-toggle btn"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-properties-1409406878"
                        >
                          Product variant
                          <span className="filter-multi-select-count"></span>
                          <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <use
                                href="#icons-solid-arrow-medium-down"
                                fill="#758CA3"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                        <div
                          className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
                          id="filter-properties-1409406878"
                        >
                          <ul
                            className="filter-multi-select-list"
                            data-id="018ea944b6d079d5831860265a2d4c4d"
                            data-title-prefix
                            data-title-postfix
                            data-title-position="2"
                            data-url-position="4"
                            data-related-property-groups='["018ea94198d97fc78914e87756acdb9f"]'
                            data-hide-from-title-on-related-property-groups="{}"
                            data-hide-category-from-title="0"
                          >
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="019097daebfe7e82bc0dcb105c015a38"
                              data-url-name="boxershorts"
                              data-title-name="Boxershorts"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Boxershorts"
                                    value="019097daebfe7e82bc0dcb105c015a38"
                                    id="019097daebfe7e82bc0dcb105c015a38"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="019097daebfe7e82bc0dcb105c015a38"
                                  >
                                    <span className="text-block">Boxershorts</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea945ee4f77e193c147db11cf6779"
                              data-url-name="goalkeeper"
                              data-title-name="goalkeeper jerseys"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Goalkeeper"
                                    value="018ea945ee4f77e193c147db11cf6779"
                                    id="018ea945ee4f77e193c147db11cf6779"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea945ee4f77e193c147db11cf6779"
                                  >
                                    <span className="text-block">Goalkeeper</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea94879777df38190af1e5c650af6"
                              data-url-name="headband"
                              data-title-name="headband"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Headband"
                                    value="018ea94879777df38190af1e5c650af6"
                                    id="018ea94879777df38190af1e5c650af6"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea94879777df38190af1e5c650af6"
                                  >
                                    <span className="text-block">Headband</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea947265f7fd8a8300d549b22d0da"
                              data-url-name="hoodies"
                              data-title-name="hoodies"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Hoodies"
                                    value="018ea947265f7fd8a8300d549b22d0da"
                                    id="018ea947265f7fd8a8300d549b22d0da"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea947265f7fd8a8300d549b22d0da"
                                  >
                                    <span className="text-block">Hoodies</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea94890167bbc8154e78de2b73537"
                              data-url-name="longsleeve"
                              data-title-name="Longsleeve"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Longsleeve"
                                    value="018ea94890167bbc8154e78de2b73537"
                                    id="018ea94890167bbc8154e78de2b73537"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea94890167bbc8154e78de2b73537"
                                  >
                                    <span className="text-block">Longsleeve</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea94612277496bfb6e297968a8330"
                              data-url-name="polo-collar"
                              data-title-name="polo collar jerseys"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Polo collar"
                                    value="018ea94612277496bfb6e297968a8330"
                                    id="018ea94612277496bfb6e297968a8330"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea94612277496bfb6e297968a8330"
                                  >
                                    <span className="text-block">Polo collar</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea9462cff75b1a53cc1c71fa82e9e"
                              data-url-name="round-collar"
                              data-title-name="round collar jerseys"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Round collar"
                                    value="018ea9462cff75b1a53cc1c71fa82e9e"
                                    id="018ea9462cff75b1a53cc1c71fa82e9e"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea9462cff75b1a53cc1c71fa82e9e"
                                  >
                                    <span className="text-block">Round collar</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea947ec5e7260b561b522aa45ab30"
                              data-url-name="shorts"
                              data-title-name="Shorts"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Shorts"
                                    value="018ea947ec5e7260b561b522aa45ab30"
                                    id="018ea947ec5e7260b561b522aa45ab30"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea947ec5e7260b561b522aa45ab30"
                                  >
                                    <span className="text-block">Shorts</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="019097de76ef764bb2bb9c6875345280"
                              data-url-name="slips"
                              data-title-name="Slips"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Slips"
                                    value="019097de76ef764bb2bb9c6875345280"
                                    id="019097de76ef764bb2bb9c6875345280"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="019097de76ef764bb2bb9c6875345280"
                                  >
                                    <span className="text-block">Slips</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea946631f782ebcafd6ec0627c7b2"
                              data-url-name="tracksuit-bottoms"
                              data-title-name="tracksuit bottoms"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Tracksuit bottoms"
                                    value="018ea946631f782ebcafd6ec0627c7b2"
                                    id="018ea946631f782ebcafd6ec0627c7b2"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea946631f782ebcafd6ec0627c7b2"
                                  >
                                    <span className="text-block">
                                      Tracksuit bottoms
                                    </span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea9467fcf7d3694ce43aa58962223"
                              data-url-name="tracksuits-jackets"
                              data-title-name="tracksuit jackets"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Tracksuits jackets"
                                    value="018ea9467fcf7d3694ce43aa58962223"
                                    id="018ea9467fcf7d3694ce43aa58962223"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea9467fcf7d3694ce43aa58962223"
                                  >
                                    <span className="text-block">
                                      Tracksuits jackets
                                    </span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea945fc0f7f01b7995278475b0932"
                              data-url-name="warm-up"
                              data-title-name="warm-up jerseys"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Warm-up"
                                    value="018ea945fc0f7f01b7995278475b0932"
                                    id="018ea945fc0f7f01b7995278475b0932"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea945fc0f7f01b7995278475b0932"
                                  >
                                    <span className="text-block">Warm-up</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea947b69f71cdb96320cda75a002f"
                              data-url-name="winter-hats"
                              data-title-name="winter hats"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Winter hats"
                                    value="018ea947b69f71cdb96320cda75a002f"
                                    id="018ea947b69f71cdb96320cda75a002f"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea947b69f71cdb96320cda75a002f"
                                  >
                                    <span className="text-block">Winter hats</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea9469cf87a91827b178182c74851"
                              data-url-name="zip-hoodies"
                              data-title-name="zip hoodies"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix="1"
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Zip hoodies"
                                    value="018ea9469cf87a91827b178182c74851"
                                    id="018ea9469cf87a91827b178182c74851"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea9469cf87a91827b178182c74851"
                                  >
                                    <span className="text-block">Zip hoodies</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
                        data-filter-property-select="true"
                        data-filter-property-select-options='{"name":"properties","propertyName":"Sleeve length","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
                      >
                        <button
                          className="filter-panel-item-toggle btn"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-properties-404020801"
                        >
                          Sleeve length
                          <span className="filter-multi-select-count"></span>
                          <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <use
                                href="#icons-solid-arrow-medium-down"
                                fill="#758CA3"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                        <div
                          className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
                          id="filter-properties-404020801"
                        >
                          <ul
                            className="filter-multi-select-list"
                            data-id="b88406c496504a24b2916cd5dcc92a5c"
                            data-title-prefix
                            data-title-postfix
                            data-title-position="3"
                            data-url-position="1"
                            data-related-property-groups='["018ea94198d97fc78914e87756acdb9f"]'
                            data-hide-from-title-on-related-property-groups="{}"
                            data-hide-category-from-title="0"
                          >
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="f99452920d564f359b5a41bdc4cef088"
                              data-url-name="long-sleeved"
                              data-title-name="long-sleeved"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Long-sleeved"
                                    value="f99452920d564f359b5a41bdc4cef088"
                                    id="f99452920d564f359b5a41bdc4cef088"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="f99452920d564f359b5a41bdc4cef088"
                                  >
                                    <span className="text-block">Long-sleeved</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="47172f3a172f413986ac03325b04115e"
                              data-url-name="short-sleeved"
                              data-title-name="short-sleeved"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="Short-sleeved"
                                    value="47172f3a172f413986ac03325b04115e"
                                    id="47172f3a172f413986ac03325b04115e"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="47172f3a172f413986ac03325b04115e"
                                  >
                                    <span className="text-block">
                                      Short-sleeved
                                    </span>
                                  </label>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
                        data-filter-property-select="true"
                        data-filter-property-select-options='{"name":"properties","propertyName":"Manufacturer","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
                      >
                        <button
                          className="filter-panel-item-toggle btn"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-properties-1786367979"
                        >
                          Manufacturer
                          <span className="filter-multi-select-count"></span>
                          <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <use
                                href="#icons-solid-arrow-medium-down"
                                fill="#758CA3"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                        <div
                          className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
                          id="filter-properties-1786367979"
                        >
                          <ul
                            className="filter-multi-select-list"
                            data-id="018ea9815e0c7b59853463216905f41d"
                            data-title-prefix
                            data-title-postfix
                            data-title-position="5"
                            data-url-position="6"
                            data-related-property-groups="{}"
                            data-hide-from-title-on-related-property-groups="{}"
                            data-hide-category-from-title="0"
                          >
                            <li
                              className="filter-multi-select-list-item filter-property-select-list-item"
                              data-id="018ea9818937719b8a78b5f3c657f988"
                              data-url-name="spized"
                              data-title-name="from spized"
                              data-use-as-prefix
                              data-category-postfix
                              data-hide-listing-title-postfix
                              data-hide-category-title-postfix
                              data-category-prefix
                              data-meta-title
                              data-meta-description
                            >
                              <a
                                className="filter-multi-select-list-item-link filter-property-select-list-item-link"
                                href
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input filter-multi-select-checkbox"
                                    data-label="spized"
                                    value="018ea9818937719b8a78b5f3c657f988"
                                    id="018ea9818937719b8a78b5f3c657f988"
                                  />
                                  <label
                                    className="filter-multi-select-item-label form-check-label"
                                    for="018ea9818937719b8a78b5f3c657f988"
                                  >
                                    <span className="text-block">spized</span>
                                  </label>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
