import React, { Suspense, useEffect, useState } from "react";
import { useRef } from "react"; // Import useRef from React
import { Canvas } from "@react-three/fiber";
import {
  ContactShadows,
  Environment,
  OrbitControls,
  Preload,
} from "@react-three/drei";
import "../Styles/3d.css";
import JerseyModel from "./ShirtModel.jsx";
import state from "./index.js";
import PositionControl from "./PositionControl";
import { GiPositionMarker } from "react-icons/gi";
import { useSnapshot } from "valtio";
import {
  FaAngleRight,
  FaAngleLeft,
  FaAngleDown,
  FaAngleUp,
} from "react-icons/fa";
import { FaPaintBrush } from "react-icons/fa";
import { IoIosColorPalette } from "react-icons/io";
import { IoMdDownload } from "react-icons/io";
import { AiOutlineNumber, AiOutlineQuestionCircle } from "react-icons/ai";
import { MdGradient, MdSupport } from "react-icons/md";
import { TbCircleDottedLetterT } from "react-icons/tb";
import { TbCircleDashedNumber1 } from "react-icons/tb";
import { FaRegFileImage } from "react-icons/fa";
import { TbCircleNumber1 } from "react-icons/tb";
import html2canvas from "html2canvas";
import { useThree } from "@react-three/fiber";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { export_3d } from "../Services/Operations/ProductAPI.js";
import { useDispatch, useSelector } from "react-redux";
import { PointLight } from "three";
import { addCart } from "../Redux/Cart_System.js";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

function ThreeD() {
  const dispatch = useDispatch();
  const designs = [
    // {
    //   name: "polo-m",
    //   image: "/GLB/polo-m.png",
    //   model: "test-polo-m-design-one",
    // },

    // {
    //   name: "des2",
    //   image: "/GLB/des2.png",
    //   model: "des2",
    // },
    {
      name: "des1",
      image: "/GLB/des1.png",
      model: "des1",
    },
    // {
    //   name: "des3",
    //   image: "/GLB/des3.png",
    //   model: "des3",
    // },
    // {
    //   name: "des4",
    //   image: "/GLB/des4.png",
    //   model: "des4",
    // },
    // {
    //   name: "des5",
    //   image: "/GLB/des5.png",
    //   model: "des5",
    // },
  ];

  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const [frontNumber, setFrontNumber] = useState("");
  const [scene, setScene] = useState(null);
  const [backNumber, setBackNumber] = useState("");
  const [selectedDesign, setSelectedDesign] = useState(designs[0]);
  const [selectedPattern, setSelectedPattern] = useState({
    front: null,
    back: null,
  });
  const [openedColorPart, setOpenedColorPart] = useState(null);
  const [uploadedTexture, setUploadedTexture] = useState(null);
  const [frontText, setFrontText] = useState("");
  const [backText, setBackText] = useState("");
  const snap = useSnapshot(state);
  const [parts, setParts] = useState([]);
  const [color, setColor] = useState([]);
  const [frontLogo, setFrontLogo] = useState(null);
  const [backLogo, setBackLogo] = useState(null);
  const [partColors, setPartColors] = useState({
    front: "",
    back: "",
  });
  const [openSection, setOpenSection] = useState(null);
  const { tokenDetails } = useSelector((state) => state.user);
  console.log("tokenDetails : ", tokenDetails);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  console.log("parts : ", parts);
  const [expandedSections, setExpandedSections] = useState({
    design: true,
    position: false,
    pattern: false,
    colors: false,
    text: false,
    number: false,
    logo: false,
    download: false,
  });
  const [activeTextField, setActiveTextField] = useState(null);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function renderGLBToPNG(glbUrl, onComplete) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true });
    renderer.setSize(512, 512);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const loader = new GLTFLoader();
    loader.load(glbUrl, (gltf) => {
      const model = gltf.scene;
      scene.add(model);
      camera.position.set(0, 0, 5);
      camera.lookAt(model.position);
      renderer.render(scene, camera);
      const pngData = renderer.domElement.toDataURL("image/png");
      onComplete(pngData);
    });
  }

  const exportGLB = () => {
    console.log("Exporting GLB...");
    if (!scene) {
      console.error("Scene is undefined!");
      return;
    }
    console.log("Scene: ", scene);

    const exporter = new GLTFExporter();

    exporter.parse(
      scene,
      async (result) => {
        const gltfBlob = new Blob([JSON.stringify(result)], {
          type: "application/octet-stream",
        });
        const file = new File([gltfBlob], `${selectedDesign.model}.glb`, {
          type: "application/octet-stream",
        });
        console.log("Exported GLB File:", file);

        const glbUrl = URL.createObjectURL(file);
        renderGLBToPNG(glbUrl, async (pngData) => {
          console.log("Rendered PNG data URL:", pngData);

          try {
            const glbBase64 = await fileToBase64(file);
            dispatch(
              addCart({
                id: 1,
                price: 100,
                quantity: 1,
                image: pngData,
                glb: glbBase64,
                size_id: 1,
                size_name: "M",
              })
            );
          } catch (error) {
            console.error("Error converting GLB file to base64:", error);
          }
        });
      },
      { binary: true }
    );
  };

  // const exportGLB = () => {
  //   console.log("don");
  //   if (!scene) {
  //     console.error("Scene is undefined!");
  //     return;
  //   }

  //   const exporter = new GLTFExporter();

  //   exporter.parse(
  //     scene,
  //     (result) => {
  //       const gltfBlob = new Blob([JSON.stringify(result)], {
  //         type: "application/octet-stream",
  //       });
  //       const file = new File([gltfBlob], `${selectedDesign.model}.glb`, {
  //         type: "application/octet-stream",
  //       });

  //       console.log("Exported GLB:", file);
  //       sendUpdatedGLB(file);
  //     },
  //     { binary: true }
  //   );
  // };

  // // Send the file to backend
  // const sendUpdatedGLB = async (file) => {
  //   console.log("file : ", file);
  //   // const formData = new FormData();
  //   // formData.append("model", file);
  //   // formData.append("ProductId", 1);
  //   // console.log("tokenDetails : ", tokenDetails);
  //   // try {
  //   //   const response = await export_3d(formData, tokenDetails);
  //   //   console.log("3d in export : ", response);
  //   // } catch (error) {
  //   //   console.error("Error updating profile:", error);
  //   // }
  //   dispatch(
  //     addCart({
  //       id: 1,
  //       price: 100,
  //       quantity: 1,
  //     })
  //   );
  // };

  const colorOptions = [
    "#fffc5b",
    "#f4cb39",
    "#f6a71a",
    "#f9886c",
    "#f9c1a8",
    "#f16f43",
    "#e8281e",
    "#e01c60",
    "#ae1444",
    "#932045",
    "#d95ca0",
    // "#d9a5d6",
    "#9264ad",
    "#643e72",
    "#601e45",
    "#7a99c8",
    // "#afccf1",
    "#7bb2e1",
    "#2064a6",
    "#134275",
    "#002d4d",
    "#113e74",
    "#5d8cb4",
    "#8acfd1",
    "#4abbb7",
    "#2a928a",
    "#266b6b",
    "#0d3f3f",
    "#8dd2ac",
    "#79c386",
    "#439f52",
    "#265934",
    "#bad14e",
    // "#d9f799",
    // "#faf799",
    "#c2bf4d",
    "#e6b64a",
    "#e98f2f",
    "#f98e7b",
    "#e6504a",
    "#f670b3",
    // "#fbbff2",
    "#d075d6",
    "#9f5cb0",
    // "#d0c0e8",
    "#536896",
    "#95cce6",
    "#5996c2",
    "#234769",
    "#1e6588",
    "#2a998c",
    "#226c54",
    "#5fd99a",
    // "#b8dbab",
    "#0500F7",
  ];

  useEffect(() => {
    setParts([]);
    setColor([]);
    setPartColors({
      front: "",
      back: "",
    });
  }, [selectedDesign]);

  const handleExpandToggle = (section) => {
    setExpandedSections({
      design: section === "design",
      position: section === "position",
      pattern: section === "pattern",
      colors: section === "colors",
      text: section === "text",
      number: section === "number",
      logo: section === "logo",
      download: section === "download",
    });
  };

  const handleColorPartToggle = (part) => {
    setOpenedColorPart((prevPart) => (prevPart === part ? null : part));
  };

  const handleColorChange = (part, color) => {
    setPartColors((prevColors) => ({
      ...prevColors,
      [part]: color,
    }));
  };

  const handleNumberColorChange = (part, color) => {
    setPartColors((prevColors) => ({
      ...prevColors,
      [part]: color,
    }));
  };

  const patterns = [
    { name: "texture1", image: "/asset/texture1.jpeg" },
    { name: "Texture2", image: "/asset/Texture2.jpg" },
    { name: "Texture3", image: "/asset/Texture3.jpeg" },
  ];

  const [logo, setLogo] = useState(null);

  console.log("parts : ", parts);

  useEffect(() => {
    setParts([]);
    setColor([]);
    setPartColors({
      front: "",
      back: "",
    });
  }, [selectedDesign]);

  const handleTextFieldToggle = (field) => {
    setActiveTextField((prevField) => (prevField === field ? null : field));
  };

  const frontHandleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFrontLogo(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const backHandleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setBackLogo(e.target.result); // Stores logo data URL for preview
      };
      reader.readAsDataURL(file);
    }
  };

  const designHandler = (design) => {
    setSelectedDesign(design);
    setSelectedPattern((prev) => ({
      front: prev.front,
      back: prev.back,
    }));
  };

  const handleFileUpload = (event, side) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const uploadedTexture = e.target.result;

      if (side === "front") {
        setSelectedPattern((prevState) => ({
          ...prevState,
          front: { name: "uploadedTexture", image: uploadedTexture },
        }));
      } else if (side === "back") {
        setSelectedPattern((prevState) => ({
          ...prevState,
          back: { name: "uploadedTexture", image: uploadedTexture },
        }));
      }
      else if (side === "right") {
        setSelectedPattern((prevState) => ({
          ...prevState,
          right: { name: "uploadedTexture", image: uploadedTexture },
        }));
      }
      else if (side === "left") {
        setSelectedPattern((prevState) => ({
          ...prevState,
          left: { name: "uploadedTexture", image: uploadedTexture },
        }));
      }
    };

    reader.readAsDataURL(file);
  };

  const canvasRef = useRef(null); // Create a ref to store the canvas reference

  const downloadCanvasToImage = () => {
    const canvas = document.querySelector("canvas");
    const dataURL = canvas.toDataURL();
    const link = document.createElement("a");

    link.href = dataURL;
    link.download = "canvas.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="containerSection">
      <div className="sidebar">
        {/* Design Section */}
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
              marginTop: "10px",
            }}
          >
            <FaPaintBrush />
            <h3
              onClick={() => handleExpandToggle("design")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
              className="design-sidebar"
            >
              Design
              <span>
                {expandedSections.design ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
        </div>
        {expandedSections.design && (
          <div className="Subdesign-section">
            <div className="design-section1">
              <div className="design-section" style={{ height: "fit-content" }}>
                {designs.map((design) => (
                  <div
                    key={design.name}
                    className={`design-thumbnail ${
                      selectedDesign.name === design.name ? "active" : ""
                    }`}
                    style={{
                      border:
                        selectedDesign.name === design.name
                          ? "2px solid blue"
                          : "1px solid gray",
                      padding: "5px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      maxHeight: "fit-content",
                    }}
                    onClick={() => designHandler(design)}
                  >
                    <img
                      src={design.image}
                      alt={design.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
              marginTop: "10px",
            }}
          >
            <GiPositionMarker />
            <h3
              onClick={() => handleExpandToggle("position")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
              className="design-sidebar"
            >
              Position
              <span>
                {expandedSections.position ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
        </div>
        {expandedSections.position && <PositionControl />}

        {/* Pattern Section */}
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
              marginTop: "10px",
            }}
          >
            <MdGradient />
            <h3
              onClick={() => handleExpandToggle("pattern")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Pattern
              <span>
                {expandedSections.pattern ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
        </div>
        {expandedSections.pattern && (
          <div
            className="Subdesign-section"
            style={{ marginTop: "15px", flexDirection: "column" }}
          >
            <div
              className="design-section1"
              style={{
                padding: "10px",
                width: "100%",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <p>Front Pattern : </p>
              <div
                className="pattern-section"
                style={{
                  display: "flex",
                  height: "fit-content",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                }}
              >
                {patterns.map((pattern) => (
                  <div
                    key={`front-${pattern.name}`}
                    className={`pattern-thumbnail ${
                      selectedPattern.front?.name === pattern.name
                        ? "active"
                        : ""
                    }`}
                    style={{
                      border:
                        selectedPattern.front?.name === pattern.name
                          ? "3px solid #007BFF"
                          : "2px solid #ddd",
                      padding: "8px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transform:
                        selectedPattern.front?.name === pattern.name
                          ? "scale(1.1)"
                          : "scale(1)",
                      width: "114px",
                      height: "114px",
                    }}
                    onClick={() =>
                      setSelectedPattern((prev) => ({
                        ...prev,
                        front: pattern,
                      }))
                    }
                  >
                    <img
                      src={pattern.image}
                      alt={pattern.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
              <div
                className="input-group"
                style={{ marginTop: "20px", zIndex: "1000000" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "front")}
                  style={{ width: "84%", margin: "auto" }}
                />
              </div>
              <p>Back Pattern : </p>
              <div
                className="pattern-section"
                style={{
                  display: "flex",
                  height: "fit-content",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                }}
              >
                {patterns.map((pattern) => (
                  <div
                    key={`back-${pattern.name}`}
                    className={`pattern-thumbnail ${
                      selectedPattern.back?.name === pattern.name
                        ? "active"
                        : ""
                    }`}
                    style={{
                      border:
                        selectedPattern.back?.name === pattern.name
                          ? "3px solid #007BFF"
                          : "2px solid #ddd",
                      padding: "8px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transform:
                        selectedPattern.back?.name === pattern.name
                          ? "scale(1.1)"
                          : "scale(1)",
                      width: "114px",
                      height: "114px",
                    }}
                    onClick={() =>
                      setSelectedPattern((prev) => ({
                        ...prev,
                        back: pattern,
                      }))
                    }
                  >
                    <img
                      src={pattern.image}
                      alt={pattern.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>

              <div
                className="input-group"
                style={{ marginTop: "20px", zIndex: "1000000" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "back")}
                  style={{ width: "84%", margin: "auto" }}
                />
              </div>
              <p>Left Sleeves Pattern : </p>
              <div
                className="pattern-section"
                style={{
                  display: "flex",
                  height: "fit-content",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                }}
              >
                {patterns.map((pattern) => (
                  <div
                    key={`back-${pattern.name}`}
                    className={`pattern-thumbnail ${
                      selectedPattern.left?.name === pattern.name
                        ? "active"
                        : ""
                    }`}
                    style={{
                      border:
                        selectedPattern.left?.name === pattern.name
                          ? "3px solid #007BFF"
                          : "2px solid #ddd",
                      padding: "8px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transform:
                        selectedPattern.left?.name === pattern.name
                          ? "scale(1.1)"
                          : "scale(1)",
                      width: "114px",
                      height: "114px",
                    }}
                    onClick={() =>
                      setSelectedPattern((prev) => ({
                        ...prev,
                        left: pattern,
                      }))
                    }
                  >
                    <img
                      src={pattern.image}
                      alt={pattern.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>

              <div
                className="input-group"
                style={{ marginTop: "20px", zIndex: "1000000" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "left")}
                  style={{ width: "84%", margin: "auto" }}
                />
              </div>
              <p>Right Sleeves Pattern : </p>
              <div
                className="pattern-section"
                style={{
                  display: "flex",
                  height: "fit-content",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                }}
              >
                {patterns.map((pattern) => (
                  <div
                    key={`back-${pattern.name}`}
                    className={`pattern-thumbnail ${
                      selectedPattern.right?.name === pattern.name
                        ? "active"
                        : ""
                    }`}
                    style={{
                      border:
                        selectedPattern.right?.name === pattern.name
                          ? "3px solid #007BFF"
                          : "2px solid #ddd",
                      padding: "8px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transform:
                        selectedPattern.right?.name === pattern.name
                          ? "scale(1.1)"
                          : "scale(1)",
                      width: "114px",
                      height: "114px",
                    }}
                    onClick={() =>
                      setSelectedPattern((prev) => ({
                        ...prev,
                        right: pattern,
                      }))
                    }
                  >
                    <img
                      src={pattern.image}
                      alt={pattern.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>

              <div
                className="input-group"
                style={{ marginTop: "20px", zIndex: "1000000" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "right")}
                  style={{ width: "84%", margin: "auto" }}
                />
              </div>
            </div>
          </div>
        )}

        {/* Colors Section */}
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
              marginTop: "10px",
            }}
          >
            <IoIosColorPalette style={{ fontSize: "22px" }} />
            <h3
              onClick={() => handleExpandToggle("colors")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Colors{" "}
              <span>
                {expandedSections.colors ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
        </div>
        {expandedSections.colors && (
          <div className="Subdesign-section">
            <div className="design-section1" style={{ width: "100%" }}>
              <div
                className="color-selection"
                style={{
                  padding: "20px",
                  width: "100%",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                {parts.map((part, index) => (
                  <div
                    key={part}
                    className="color-section"
                    style={{ marginBottom: "10px" }}
                  >
                    {/* Color Preview Circle */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: partColors[part] || "white",
                          border: "2px solid #333",
                        }}
                      />
                      <p
                        onClick={() => handleColorPartToggle(part)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          marginBottom: "0px",
                          fontSize: "20px",
                        }}
                      >
                        {`Color ${index + 1}`}
                        <span>
                          {openedColorPart === part ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                    </div>
                    {openedColorPart === part && (
                      <div className="color-picker">
                        {colorOptions.map((color, colorIndex) => (
                          <div
                            key={colorIndex}
                            className={`color-box ${
                              partColors[part] === color ? "selected" : ""
                            }`}
                            onClick={() => handleColorChange(part, color)}
                            style={{ backgroundColor: "transparent" }}
                          >
                            <div
                              className="inner-color"
                              style={{ backgroundColor: color }}
                            ></div>
                            {partColors[part] === color && (
                              <div className="checkmark">✓</div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Text Section */}
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
              marginTop: "10px",
            }}
          >
            <TbCircleDottedLetterT style={{ fontSize: "22px" }} />
            <h3
              onClick={() => handleExpandToggle("text")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Text{" "}
              <span>
                {expandedSections.text ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
          {expandedSections.colors && (
  <div className="Subdesign-section">
    <div className="design-section1" style={{ width: "100%" }}>
      <div
        className="color-selection"
        style={{
          padding: "20px",
          width: "100%",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {parts.map((part, index) => (
          <div
            key={part.name}
            className="color-section"
            style={{ marginBottom: "10px" }}
          >
            {/* Color Preview Circle */}
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: partColors[part.name] || "white",
                  border: "2px solid #333",
                }}
              />
              <p
                onClick={() => handleColorPartToggle(part.name)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  width: "100%",
                  marginBottom: "0px",
                  fontSize: "20px",
                }}
              >
                {part.name}  {/* Display the actual mesh name */}
                <span>
                  {openedColorPart === part.name ? (
                    <FaAngleUp />
                  ) : (
                    <FaAngleDown />
                  )}
                </span>
              </p>
            </div>

            {openedColorPart === part.name && (
              <div className="color-picker">
                {colorOptions.map((color, colorIndex) => (
                  <div
                    key={colorIndex}
                    className={`color-box ${
                      partColors[part.name] === color ? "selected" : ""
                    }`}
                    onClick={() => handleColorChange(part.name, color)}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div
                      className="inner-color"
                      style={{ backgroundColor: color }}
                    ></div>
                    {partColors[part.name] === color && (
                      <div className="checkmark">✓</div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
)}

        </div>

        {/* Number Section */}
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
            }}
          >
            <TbCircleDashedNumber1 style={{ fontSize: "22px" }} />
            <h3
              onClick={() => handleExpandToggle("number")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Number
              <span>
                {expandedSections.number ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
          {expandedSections.number && (
            <div className="Subdesign-section">
              <div className="design-section1">
                <div className="text-section">
                  <div className="input-group">
                    <label htmlFor="front-text" style={{ fontSize: "19px" }}>
                      Front Number:
                    </label>
                    <input
                      id="front-text"
                      type="number"
                      placeholder="Enter Number"
                      value={frontNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFrontNumber(value);
                        state.frontNumber = value;
                      }}
                    />
                  </div>
                  {frontNumber && (
                    <div>
                      <p
                        onClick={() => handleTextFieldToggle("front")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Front Number Colors
                        <span>
                          {activeTextField === "front" ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                      {activeTextField === "front" && (
                        <div className="color-picker">
                          {colorOptions.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className={`color-box ${
                                partColors["frontNumber"] === color
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleNumberColorChange("frontNumber", color)
                              }
                              style={{
                                backgroundColor: color,
                                borderRadius: "50%",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="input-group">
                    <label htmlFor="front-text" style={{ fontSize: "19px" }}>
                      Back Number:
                    </label>
                    <input
                      id="front-text"
                      type="number"
                      placeholder="Enter Number"
                      value={backNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        setBackNumber(value);
                        state.backNumber = value;
                      }}
                    />
                  </div>
                  {backNumber && (
                    <div>
                      <p
                        onClick={() => handleTextFieldToggle("back")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Back Number Colors
                        <span>
                          {activeTextField === "back" ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                      {activeTextField === "back" && (
                        <div className="color-picker">
                          {colorOptions.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className={`color-box ${
                                partColors["backNumber"] === color
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleNumberColorChange("backNumber", color)
                              }
                              style={{
                                backgroundColor: color,
                                borderRadius: "50%",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
            }}
          >
            <FaRegFileImage style={{ fontSize: "22px" }} />
            <h3
              onClick={() => handleExpandToggle("logo")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Logo{" "}
              <span>
                {expandedSections.logo ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>

          {expandedSections.logo && (
            // <div className="logo-uploader">
            <div className="Subdesign-section">
              <div className="design-section1">
                <>
                  <h5 style={{ padding: "10px" }}>Front Logo</h5>
                  <div
                    className="input-group"
                    style={{
                      marginTop: "20px",
                      zIndex: "1000000",
                      height: "fit-content",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={frontHandleLogoUpload}
                      style={{ width: "84%", margin: "auto" }}
                    />
                  </div>
                  <h5 style={{ padding: "10px" }}>Back Logo</h5>
                  <div
                    className="input-group"
                    style={{
                      marginTop: "20px",
                      zIndex: "1000000",
                      height: "fit-content",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={backHandleLogoUpload}
                      style={{ width: "84%", margin: "auto" }}
                    />
                  </div>
                </>
              </div>
            </div>
            // </div>
          )}
        </div>
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
              marginTop: "10px",
            }}
          >
            <FaPaintBrush />
            <h3
              onClick={() => handleExpandToggle("download")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
              className="design-sidebar"
            >
              Download
              <span>
                {expandedSections.design ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
        </div>
        <div className="download-section">
          <div className="Subdesign-section">
            <div className="design-section1">
              {expandedSections.download && (
                <div
                  className="input-group"
                  style={{
                    marginTop: "20px",
                    zIndex: "1000000",
                    height: "fit-content",
                    padding: "0px 10px",
                    opacity: "1",
                    position: "relative",
                  }}
                >
                  <button
                    onClick={downloadCanvasToImage}
                    style={{ width: "84%", margin: "auto" }}
                  >
                    Download as PNG
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
            }}
          >
            <h3
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
              className="design-sidebar"
              onClick={() => exportGLB(selectedDesign.scene)}
            >
              Export
            </h3>
          </div>
        </div>
      </div>

      <div className="main-viewport" style={{ position: "relative" }}>
        <div
          className="text-editor"
          style={{
            position: "absolute",
            bottom: "0px",
            right: "10px",
            zIndex: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              height: "100%",
              borderBottom: "1px solid #cbc6c6",
              backgroundColor: "white",
            }}
          >
            <button
              className="continue-button"
              onClick={() => exportGLB(selectedDesign.scene)}
            >
              Continue
            </button>
          </div>
        </div>

        <Canvas
          ref={canvasRef}
          // style={{ backgroundColor: "white" }}
          gl={{ preserveDrawingBuffer: true }}
          style={{ zIndex: 1 }}
        >
          {/* <ambientLight position={[5, 5, 5]} intensity={1.5} />
          <directionalLight position={[5, 5, 5]} intensity={0.5} />
          <spotLight position={[5, 5, 5]} intensity={0.5} />
          <directionalLight position={[5, 5, -75]} intensity={2} /> */}
          <ambientLight intensity={0.3} />
          <spotLight
            intensity={0.5}
            angle={0.1}
            penumbra={1}
            position={[10, 15, 10]}
            castShadow
          />
          {/* <Model /> */}
          <Environment preset="city" />
          <ContactShadows
            position={[0, -0.8, 0]}
            opacity={0.25}
            scale={10}
            blur={1.5}
            far={0.8}
          />

          <JerseyModel
            colors={partColors}
            setParts={setParts}
            setColor={setColor}
            frontLogo={frontLogo}
            backLogo={backLogo}
            modelName={selectedDesign.model}
            frontText={frontText}
            backText={backText}
            frontNumber={frontNumber}
            backNumber={backNumber}
            selectedPattern={selectedPattern}
            setScene={setScene}
          />
        </Canvas>
      </div>
    </div>
  );
}

export default ThreeD;
