import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

function Designed() {
  return (
    <div>
      <div className="cms-section bg-color pos-4 cms-section-default" style={{ backgroundColor: "#f4f4f4", paddingTop:50, paddingBottom:50 }}>
        <div className="cms-section-default full-width">
          <div className="cms-block block-container-3 pos-0 cms-block-spized-video-steps">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row">
                <div className="col-12">
                  <div className="image-text-slider-element cms-element-spized-video-steps">
                    <Swiper
                      pagination={{ clickable: true }}
                      breakpoints={{
                        320: { slidesPerView: 1 },
                        575: { slidesPerView: 1 },
                        767: { slidesPerView: 1 },
                        992: { slidesPerView: 3 },
                        1220: { slidesPerView: 3 },
                      }}
                      loop={true}
                    >
                      {/* Slide 1 */}
                      <SwiperSlide>
                      <div className="swiper-slide image-slider-item-container list-item swiper-slide-active" data-swiper-slide-index="0" style={{width: "250.667px"}} role="group" aria-label="4 / 9">
                          <span className="number t1largest">1</span>
                          <div className="title t1small">Designed by You
                          </div>
                          <div className="description-box t3">
                          Create your own custom designs in minutes with our easy-to-use 3D configurator.
                          </div>
                          {/* <a className="btn button2 button-secondary" href="">
                            How to order?
                            <i className="icon-arrow-right-bold"></i>
                          </a> */}
                        </div>
                      </SwiperSlide>

                      {/* Slide 2 */}
                      <SwiperSlide>
                      <div className="swiper-slide image-slider-item-container list-item swiper-slide-active" data-swiper-slide-index="0" style={{width: "250.667px"}} role="group" aria-label="4 / 9">

                          <span className="number t1largest">2</span>
                          <div className="title t1small">Made by Us
                          </div>
                          <div className="description-box t3">
                          Crafted from premium materials with top-notch print quality—100% made in the USA.

                          </div>
                          {/* <a className="btn button2 button-secondary" href="/en/materials">
                            Materials
                            <i className="icon-arrow-right-bold"></i>
                          </a> */}
                        </div>
                      </SwiperSlide>

                      {/* Slide 3 */}
                      <SwiperSlide>
                      <div className="swiper-slide image-slider-item-container list-item swiper-slide-active" data-swiper-slide-index="0" style={{width: "250.667px"}} role="group" aria-label="4 / 9">

                          <span className="number t1largest">3</span>
                          <div className="title t1small">Fast Delivery, Every Time
                          </div>
                          <div className="description-box t3">
                          Say goodbye to long waits! We redefine speed with industry-leading logistics and fast delivery times.

                          </div>
                          {/* <a className="btn button2 button-secondary" href="/en/delivery-times">
                            Delivery time
                            <i className="icon-arrow-right-bold"></i>
                          </a> */}
                        </div>
                      </SwiperSlide>
                      
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Designed;
