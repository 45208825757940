import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import state from "./index.js";
import { useSnapshot } from "valtio";
import { OrbitControls, Decal } from "@react-three/drei";
import { Html } from "@react-three/drei";
import Spinner from "react-bootstrap/Spinner";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

function ShirtModel({
  colors,
  setParts,
  frontLogo,
  backLogo,
  modelName,
  frontText,
  backText,
  frontNumber,
  backNumber,
  selectedPattern,
  setScene,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const snap = useSnapshot(state);
  const group = useRef();

  const [gltf, setGltf] = useState(null);

  console.log("colors : ", colors);
  console.log("selectedPattern : ", selectedPattern);

  useEffect(() => {
    setIsLoading(true);
    setGltf("");

    // Set up DRACOLoader
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");

    // Set up GLTFLoader with DRACOLoader
    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);

    loader.load(`/GLB/${modelName}.glb`, (model) => {
      setGltf(model);
      setIsLoading(false);
    });
  }, [modelName]);

  useEffect(() => {
    if (gltf?.scene && setScene) {
      setScene(gltf.scene);
    }
  }, [gltf, setScene]);

  console.log("gltf : ", gltf);
  console.log("isLoading : ", isLoading);

  const [parts, setLocalParts] = useState([]);
  const [frontParts, setFrontParts] = useState([]);
  const [backParts, setBackParts] = useState([]);
  const [leftParts, setLeftParts] = useState([]);
  const [rightParts, setRightParts] = useState([]);
  const [designParts, setDesignParts] = useState([]);

  console.log("parts : ", parts);
  console.log("rightParts : ", rightParts);
  console.log("leftParts : ", leftParts);
  console.log("frontParts : ", frontParts);

  const frontPatternTexture = useMemo(() => {
    if (!selectedPattern?.front?.image) return null;
    const texture = new THREE.TextureLoader().load(selectedPattern.front.image);
    texture.colorSpace = THREE.SRGBColorSpace;
    return texture;
  }, [selectedPattern?.front?.image]);

  const backPatternTexture = useMemo(() => {
    if (!selectedPattern?.back?.image) return null;
    const texture = new THREE.TextureLoader().load(selectedPattern.back.image);
    texture.colorSpace = THREE.SRGBColorSpace;
    return texture;
  }, [selectedPattern?.back?.image]);

  const leftPatternTexture = useMemo(() => {
    if (!selectedPattern?.left?.image) return null;
    const texture = new THREE.TextureLoader().load(selectedPattern.left.image);
    texture.colorSpace = THREE.SRGBColorSpace;
    return texture;
  }, [selectedPattern?.left?.image]);

  const rightPatternTexture = useMemo(() => {
    if (!selectedPattern?.right?.image) return null;
    const texture = new THREE.TextureLoader().load(selectedPattern.right.image);
    texture.colorSpace = THREE.SRGBColorSpace;
    return texture;
  }, [selectedPattern?.right?.image]);

  /** ==========================
   *  OPTIMIZED MESH EXTRACTION
   *  ========================== */
  useEffect(() => {
    if (gltf?.scene) {
      const extractedParts = [];
      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          extractedParts.push({
            name: child.name,
            geometry: child.geometry,
            material: child.material,
          });
        }
      });

      setLocalParts(extractedParts);
      setParts?.(extractedParts.map((part) => part.name));
    }

    if (gltf?.scene) {
      const frontMeshNames = [];
      const backMeshNames = [];
      const leftMeshNames = [];
      const rightMeshNames = [];

      gltf.scene.children.forEach((child) => {
        const nameLower = child.name.toLowerCase();

        if (nameLower === "front") {
          frontMeshNames.push(
            ...child.children.filter((c) => c.isMesh).map((mesh) => mesh.name)
          );
        } else if (nameLower === "design") {
          const designMeshNames = child.children
            .filter((c) => c.isMesh)
            .map((mesh) => mesh.name);
          setDesignParts(designMeshNames); // Store separately
        } else if (nameLower === "back") {
          if (child.children.length > 0) {
            backMeshNames.push(
              ...child.children.filter((c) => c.isMesh).map((mesh) => mesh.name)
            );
          } else {
            backMeshNames.push(child.name);
          }
        } else if (nameLower === "leftsleeve") {
          if (child.children.length > 0) {
            leftMeshNames.push(
              ...child.children.filter((c) => c.isMesh).map((mesh) => mesh.name)
            );
          } else {
            leftMeshNames.push(child.name); // Add this line
          }
        } else if (nameLower === "rightsleeve") {
          if (child.children.length > 0) {
            rightMeshNames.push(
              ...child.children.filter((c) => c.isMesh).map((mesh) => mesh.name)
            );
          } else {
            rightMeshNames.push(child.name); // Add this line
          }
        }
      });

      setFrontParts(frontMeshNames);
      setBackParts(backMeshNames);
      setLeftParts(leftMeshNames);
      setRightParts(rightMeshNames);
    }

    // Cleanup to free GPU memory
    return () => {
      if (gltf?.scene) {
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.geometry.dispose();
            if (child.material.map) child.material.map.dispose();
            child.material.dispose();
          }
        });
      }
    };
  }, [gltf, setParts]);

  /** ==========================
   *  OPTIMIZED COLOR UPDATE
   *  ========================== */
  useEffect(() => {
    if (colors && parts.length) {
      parts.forEach((part) => {
        if (colors[part.name]) {
          part.material.color.set(colors[part.name]);
        }
      });
    }
  }, [colors, parts]);

  /** ==========================
   *  MEMOIZED TEXTURE LOADING
   *  ========================== */
  const logoTexture = useMemo(() => {
    if (!frontLogo) return null;
    const texture = new THREE.TextureLoader().load(frontLogo);
    texture.colorSpace = THREE.SRGBColorSpace;
    return texture;
  }, [frontLogo]);

  const logoTextureBack = useMemo(() => {
    if (!backLogo) return null;
    const texture = new THREE.TextureLoader().load(backLogo);
    texture.colorSpace = THREE.SRGBColorSpace;
    return texture;
  }, [backLogo]);

  /** ==========================
   *  OPTIMIZED TEXTURE CREATION
   *  ========================== */
  const createTextTexture = useCallback(
    (text, font = "Arial", size = 50, color = "white") => {
      if (!text) return null;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const resolutionMultiplier = 4;
      const adjustedSize = size * resolutionMultiplier;

      ctx.font = `${adjustedSize}px ${font}`;
      const textWidth = ctx.measureText(text).width;
      const textHeight = adjustedSize;

      canvas.width = Math.ceil((textWidth + 50) * resolutionMultiplier);
      canvas.height = Math.ceil((textHeight + 25) * resolutionMultiplier);

      ctx.font = `${adjustedSize}px ${font}`;
      ctx.fillStyle = color;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, canvas.width / 2, canvas.height / 2);

      const texture = new THREE.CanvasTexture(canvas);
      texture.anisotropy = 16;
      texture.generateMipmaps = true;
      texture.minFilter = THREE.LinearMipmapLinearFilter;
      texture.magFilter = THREE.LinearFilter;

      return texture;
    },
    []
  );

  // Memoize text textures
  const frontTextTexture = useMemo(
    () => createTextTexture(frontText, "Arial", 25, colors.front || "black"),
    [frontText, colors.front]
  );
  const frontNumberTexture = useMemo(
    () =>
      createTextTexture(
        frontNumber,
        "Arial",
        25,
        colors.frontNumber || "black"
      ),
    [frontNumber, colors.frontNumber]
  );
  const backTextTexture = useMemo(
    () => createTextTexture(backText, "Arial", 25, colors.backColor || "black"),
    [backText, colors.back]
  );
  const backNumberTexture = useMemo(
    () =>
      createTextTexture(backNumber, "Arial", 25, colors.backNumber || "black"),
    [backNumber, colors.backNumber]
  );

  return (
    <>
      <OrbitControls
        enableZoom
        enablePan
        target={[0, 0, 0]}
        enableRotate
        rotateSpeed={3.0}
      />

      {isLoading && (
        <Html center>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(197, 197, 197, 0.8)",
              padding: "5px 15px",
              borderRadius: "8px",
              gap: "20px",
            }}
          >
            <Spinner animation="border" variant="dark" />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
              }}
            >
              Loading...
            </p>
          </div>
        </Html>
      )}

      {!isLoading && (
        <group
          ref={group}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.008, 0.008, 0.008]}
        >
          {parts.map((part, index) => {
            console.log(part.name);
            return (
              <mesh
                key={index}
                geometry={part.geometry}
                material={part.material}
                name={part.name}
              >
               {(frontParts.includes(part.name)) &&
                  !leftParts.includes(part.name) &&
                  !rightParts.includes(part.name) &&
                  frontPatternTexture && (
                    <meshStandardMaterial
                      map={frontPatternTexture}
                      attach="material"
                    />
                  )}

                {/* {designParts.includes(part.name) &&
                  selectedPattern?.design?.image && (
                    <meshStandardMaterial
                      map={new THREE.TextureLoader().load(
                        selectedPattern.design.image
                      )}
                      attach="material"
                    />
                  )} */}

                {backParts.includes(part.name) && backPatternTexture && (
                  <meshStandardMaterial
                    map={backPatternTexture}
                    attach="material"
                  />
                )}

                {leftParts.includes(part.name) && leftPatternTexture && (
                  <meshStandardMaterial
                    map={leftPatternTexture}
                    attach="material"
                  />
                )}

                {rightParts.includes(part.name) && rightPatternTexture && (
                  <meshStandardMaterial
                    map={rightPatternTexture}
                    attach="material"
                  />
                )}

                {(frontParts.includes(part.name) || designParts.includes(part.name)) && frontTextTexture && (
                  <Decal
                    position={snap.frontTextPosition}
                    rotation={snap.frontTextRotation}
                    scale={snap.frontTextScale}
                    map={frontTextTexture}
                  />
                )}
                {frontParts.includes(part.name) && frontNumberTexture && (
                  <Decal
                    position={snap.frontNumberposition}
                    rotation={snap.frontNumberrotation}
                    scale={snap.frontNumberscale}
                    map={frontNumberTexture}
                  />
                )}
                {frontParts.includes(part.name) && logoTexture && (
                  <Decal
                    position={snap.frontLogoposition}
                    rotation={snap.frontLogorotation}
                    scale={snap.frontLogoscale}
                    map={logoTexture}
                  />
                )}
                {backParts.includes(part.name) && backTextTexture && (
                  <Decal
                    position={snap.backTextPosition}
                    rotation={snap.backTextRotation}
                    scale={snap.backTextScale}
                    map={backTextTexture}
                  />
                )}
                {backParts.includes(part.name) && backNumberTexture && (
                  <Decal
                    position={snap.backNumberposition}
                    rotation={snap.backNumberrotation}
                    scale={snap.backNumberscale}
                    map={backNumberTexture}
                  />
                )}
                {backParts.includes(part.name) && logoTextureBack && (
                  <Decal
                    position={snap.backLogoposition}
                    rotation={snap.backLogorotation}
                    scale={snap.backLogoscale}
                    map={logoTextureBack}
                  />
                )}
              </mesh>
            );
          })}
        </group>
      )}
    </>
  );
}

export default ShirtModel;
