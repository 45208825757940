import api from "../api";
import {
  register,
  profile,
  Config,
  menu,
  stock,
  PlaceOrder_endpoint,
  check_endpoint,
  ForgetPassward_endpoint,
  Three_endpoint
} from "../api_endpoints";
const { REGISTER_API, LOGIN_API, LOGOUT_API } = register;
const {
  UPDATEPROILE_API,
  PROILE_API,
  IMAGEUPDATE_API,
  GETUSER_API,
  DELETEUSER_API,
  UPDATEUSER_API,
  ADDUSER_API,
} = profile;
const { CONFIG_API } = Config;
const { MENU_API, MENUSLUG_API, product_API, productDetails_API } = menu;
const { STOCK_API } = stock;
const { PlaceOrder_API, PaymentAdd_API, VerifyPayment_API,OrderList_API,OrderDetails_API } =
  PlaceOrder_endpoint;
const { coupon_API } = check_endpoint;
const {forgetPassward_API} = ForgetPassward_endpoint;
const {Three_API} = Three_endpoint;

export const config_Api = async () => {
  let result = [];
  try {
    const response = await api.post(CONFIG_API);
    console.log("config : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const get_register = async (data) => {
  let result = [];
  try {
    const response = await api.post(REGISTER_API, data);
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};
export const get_login = async (data) => {
  let result = [];
  try {
    console.log("data : ", data);
    const response = await api.post(LOGIN_API, data);
    console.log("Login : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const get_logout = async (token) => {
  let result = [];
  try {
    const response = await api.post(
      LOGOUT_API,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Logout : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const updateProfile = async (data, token) => {
  let result = [];
  try {
    const response = await api.post(UPDATEPROILE_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const getProfile = async (token) => {
  let result = [];
  try {
    const response = await api.post(
      PROILE_API,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const updateImage = async (data, token) => {
  let result = [];
  try {
    console.log("data : ", data);
    const response = await api.post(IMAGEUPDATE_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const menuSection = async () => {
  let result = [];
  try {
    const response = await api.post(MENU_API, {});
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const menuSlugSection = async (data) => {
  let result = [];
  try {
    const response = await api.post(MENUSLUG_API, data);
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const productApi = async (data) => {
  let result = [];
  try {
    const response = await api.post(product_API, data);
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const productDetails = async (data) => {
  let result = [];
  try {
    const response = await api.post(productDetails_API, data);
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const stockCheck = async (data) => {
  let result = [];
  try {
    const response = await api.post(STOCK_API, data);
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const addUser = async (data, token) => {
  let result = [];
  try {
    const response = await api.post(ADDUSER_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const updateUser = async (data, token) => {
  let result = [];
  try {
    const response = await api.post(UPDATEUSER_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const deleteUser = async (data, token) => {
  let result = [];
  try {
    const response = await api.post(DELETEUSER_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const getUser = async (token) => {
  let result = [];
  try {
    const response = await api.post(
      GETUSER_API,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const PlaceOrder = async (data, token) => {
  let result = [];
  try {
    console.log(">>>>>>>saifuddin<<<<<<<<<<<");
    const response = await api.post(PlaceOrder_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    result = response.data;
    return result;
  } catch (error) {
    console.log("..........");
  }
};

export const PaymentAdd = async (data, token) => {
  let result = [];
  try {
    console.log("data : ", data);
    const response = await api.post(PaymentAdd_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    result = response.data;
    return result;
  } catch (error) {
    console.log("GET_logOut_API_ERROR..........");
  }
};

export const orderValidate = async (data, token) => {
  let result = [];
  try {
    console.log("data : ", data);
    const response = await api.post(VerifyPayment_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    result = response.data;
    return result;
  } catch (error) {
    console.log("GET_logOut_API_ERROR..........");
  }
};

export const Coupon_data = async (data, token) => {
  let result = [];
  console.log("token : ",token)
  try {
    console.log(">>>>>>>saifuddin<<<<<<<<<<<");
    const response = await api.post(coupon_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Coupon Api : ", response);
    result = response.data;
    return result;
  } catch (error) {
    console.log("GET_login_API_ERROR..........");
  }
};

export const orderList = async (token) => {
  let result = [];
  try {
    const response = await api.post(
      OrderList_API,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log("GET_logOut_API_ERROR..........");
  }
};

export const particularOrder = async (data,token) => {
  let result = [];
  try {
    const response = await api.post(
      OrderDetails_API,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log("GET_logOut_API_ERROR..........");
  }
};

export const forgetPasswardChange = async (data) => {
  let result = [];
  try {
    const response = await api.post(
      forgetPassward_API,
      data
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log("GET_logOut_API_ERROR..........");
  }
};

export const export_3d = async (data,token) => {
  let result = [];
  console.log("token : ",token)
  try {
    const response = await api.post(Three_API,data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("data : ",data)
    console.log("config : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};
