const baseURL= "https://backend.sheildsports.com/api/v1/"

//config endpoint
export const Config={
    CONFIG_API:baseURL+"webconfig",
}

//register endpoint
export const register={
    REGISTER_API:baseURL+"customer/register",
    LOGIN_API:baseURL+"customer/login",
    LOGOUT_API:baseURL+"customer/logout",
}

//profile endpoint
export const profile={
    UPDATEPROILE_API:baseURL+"customer/updateprofile",
    PROILE_API:baseURL+"customer/profile",
    IMAGEUPDATE_API:baseURL+"customer/updateprofileimg",
    ADDUSER_API:baseURL+"customer/addUserAddress",
    UPDATEUSER_API:baseURL+"customer/updateUserAddress",
    DELETEUSER_API:baseURL+"customer/deleteUserAddress",
    GETUSER_API:baseURL+"customer/getUserAddressList",
}

//category and Product endpoint
export const menu={
    MENU_API:baseURL+"getAllMenus",
    MENUSLUG_API:baseURL+"getCategoriesByMenuSlug",
    product_API:baseURL+"getAllProductByCategory",
    productDetails_API:baseURL+"getProductBySlug",
}

//stock Product endpoint
export const stock={
    STOCK_API:baseURL+"checkProductStock",
}

//Place Order endpoint
export const PlaceOrder_endpoint={
    PlaceOrder_API:baseURL+"customer/placeOrder",
    PaymentAdd_API:baseURL+"customer/paymentAdd",
    VerifyPayment_API:baseURL+"customer/verifyPayments",
    OrderList_API:baseURL+"customer/getOrderList",
    OrderDetails_API:baseURL+"customer/getOrderDetailById",
}

//coupon endpoint
export const check_endpoint={
    coupon_API:baseURL+"customer/applyCoupon",
}

//forget passward endpoint
export const ForgetPassward_endpoint={
    forgetPassward_API:baseURL+"forgotPassword",
}

//forget passward endpoint
export const Three_endpoint={
    Three_API:baseURL+"customer/customizeProductByUser",
}
