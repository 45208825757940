import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { particularOrder } from "../../Services/Operations/ProductAPI";
import Header from "../Components/Header";
import { useSelector } from "react-redux";
import "../../Styles/Details.css"

const OrderDetails = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const [orderData, setOrderData] = useState(null);
  const { tokenDetails } = useSelector((state) => state.user);
  const token = tokenDetails;
    const { configUrl } = useSelector((state) => state.cart);
    console.log("configUrl : ",configUrl)

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await particularOrder({ orderId: id }, token);
        console.log("Particular data : ",response);
        if (response.success) {
          setOrderData(response.data[0]);
        } else {
          console.error("Failed to fetch order details");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id]);

  if (!orderData) {
    return <div>Loading order details...</div>;
  }

  const formattedDate = new Date(orderData.created_at).toLocaleDateString();
  const formattedTime = new Date(orderData.created_at).toLocaleTimeString();

  return (
    <div className="order-details-container">
      <Header />
      <div style={styles.container}>
        <h2 style={styles.header}>Order Details</h2>

        <div style={styles.orderCard}>
          <div style={styles.orderSection}>
            <h3 style={styles.sectionHeader}>Order Summary</h3>
            <div style={styles.row}>
              <span style={styles.label}>Order ID:</span>
              <span style={styles.value}>{orderData.id}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Date:</span>
              <span style={styles.value}>{formattedDate}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Time:</span>
              <span style={styles.value}>{formattedTime}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Order Type:</span>
              <span style={styles.value}>{orderData.order_type}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Order Status:</span>
              <span
                style={{
                  ...styles.value,
                  color: orderData.order_status === "Pending" ? "#ff4d4f" : "#28a745",
                }}
              >
                {orderData.order_status}
              </span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Payment Status:</span>
              <span
                style={{
                  ...styles.value,
                  color: orderData.payment_status === "Pending" ? "#ff4d4f" : "#28a745",
                }}
              >
                {orderData.payment_status}
              </span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Coupon Code:</span>
              <span style={styles.value}>{orderData.coupon_code || "N/A"}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Total Discount:</span>
              <span style={styles.value}>${orderData.total_discount.toFixed(2)}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Total Amount:</span>
              <span style={styles.value}>${orderData.total_amount.toFixed(2)}</span>
            </div>
          </div>

          <div style={styles.orderSection}>
            <h3 style={styles.sectionHeader}>Delivery Address</h3>
            <div style={styles.row}>
              <span style={styles.label}>Name:</span>
              <span style={styles.value}>{orderData.address.name}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Mobile:</span>
              <span style={styles.value}>{orderData.address.mobile_no}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>Address:</span>
              <span style={styles.value}>{orderData.address.address}</span>
            </div>
            <div style={styles.row}>
              <span style={styles.label}>City:</span>
              <span style={styles.value}>{orderData.address.city}</span>
            </div>
          </div>

          <div style={styles.orderSection}>
            <h3 style={styles.sectionHeader}>Items</h3>
            {orderData.items.map((item, index) => (
              <div key={index} style={styles.itemCard}>
                <img
                  src={`${configUrl}${item.product.image}`}
                  alt={item.product.name}
                  className="product-image"
                />
                <div style={styles.itemDetails}>
                  <div style={styles.row}>
                    <span style={styles.label}>Product:</span>
                    <span style={styles.value}>{item.product.name}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Size:</span>
                    <span style={styles.value}>{item.size.name}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Quantity:</span>
                    <span style={styles.value}>{item.quantity}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Price:</span>
                    <span style={styles.value}>${item.amount.toFixed(2)}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Total:</span>
                    <span style={styles.value}>${item.total_amount.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "90%",
    margin: "20px auto",
    textAlign: "center",
    // fontFamily: "Arial, sans-serif",
  },
  header: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
    textAlign: "center",
  },
  orderCard: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    textAlign: "left",
  },
  orderSection: {
    marginBottom: "20px",
  },
  sectionHeader: {
    fontSize: "21px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#ffffff",
    backgroundImage: "linear-gradient(90deg, rgb(237 205 156), rgb(237 140 65))",
    padding: "10px 20px",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    marginBottom: "20px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
    fontSize: "16px",
    flexWrap: "wrap",
  },
  label: {
    fontWeight: "bold",
    color: "#555",
    flexBasis: "40%", 
    textAlign: "left",
  },
  value: {
    color: "#333",
    flexBasis: "55%", 
    textAlign: "right",
  },
  itemCard: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "10px",
    border: "1px solid #eee",
    borderRadius: "8px",
    marginBottom: "10px",
    alignItems: "center",
    flexWrap: "wrap", 
  },
  // productImage: {
  //   width: "128px",
  //   height: "100%",
  //   borderRadius: "8px",
  //   objectFit: "cover",
  // },
  itemDetails: {
    flex: 1,
    minWidth: "180px", 
  },
  "@media (max-width: 768px)": {
    container: {
      width: "95%",
    },
    row: {
      fontSize: "14px",
      flexDirection: "column", 
      alignItems: "flex-start",
    },
    label: {
      flexBasis: "100%", 
      textAlign: "left",
    },
    value: {
      flexBasis: "100%",
      textAlign: "left",
    },
    itemCard: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    // productImage: {
    //   width: "100%",
    //   height: "207px",
    //   marginBottom: "10px",
    //   objectFit:"fill"
    // },
    sectionHeader: {
      fontSize: "18px",
      padding: "8px 16px",
    },
  },
};


export default OrderDetails;
