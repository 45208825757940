import React, { useEffect, useState, useRef, useCallback } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiMenu3Line } from "react-icons/ri";
import Sports from "../HeaderContent/Sports";
import AccountSec from "../HeaderContent/AccountSec";
import "../Css/fonts.css";
import { useNavigate } from "react-router-dom";
import {
  menuSection,
  menuSlugSection,
  productApi,
} from "../../Services/Operations/ProductAPI";
import { AiOutlineDown } from "react-icons/ai";
import "../../Styles/Header.css";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";

function Header() {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  // const { tokenDetails } = useSelector((state) => state.user);
  const [menu, setMenu] = useState([]);
  const [menuSlugName, setMenuSlugName] = useState("");
  const [menuSubcategory, setmenuSubcategory] = useState("");
  const [menuSlug, setMenuSlug] = useState([]);
  const [product, setProduct] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const { totalItems } = useSelector((state) => state.cart);
  console.log("totalItems : ", totalItems);

  // const token = tokenDetails;

  // const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const [clickedItemIndex, setClickedItemIndex] = useState(null);

  const toggleDropdown = (index) => {
    setClickedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setClickedItemIndex(null); // Close dropdown
    }
  };

  // const toggleDropdown = (index) => {
  //   setHoveredItemIndex(index);
  // };

  // const closeDropdown = () => {
  //   setHoveredItemIndex(null);
  // };

  const list = [
    {
      cat_id: 1,
      title: "Badminton",
      subTitles: [
        { id: 1, sub: "Badminton Jersey" },
        { id: 2, sub: "Badminton Shorts" },
        { id: 3, sub: "Badminton Tank Top" },
      ],
    },
    {
      cat_id: 2,
      title: "Football",
      subTitles: [
        { id: 1, sub: "Football Jersey" },
        { id: 2, sub: "Football Shorts" },
        { id: 3, sub: "Football Shoes" },
      ],
    },
    {
      cat_id: 3,
      title: "Basketball",
      subTitles: [
        { id: 1, sub: "Basketball Jersey" },
        { id: 2, sub: "Basketball Shorts" },
        { id: 3, sub: "Basketball Shoes" },
      ],
    },
    {
      cat_id: 4,
      title: "Tennis",
      subTitles: [
        { id: 1, sub: "Tennis Racket" },
        { id: 2, sub: "Tennis Shorts" },
        { id: 3, sub: "Tennis Shoes" },
      ],
    },
    {
      cat_id: 5,
      title: "Running",
      subTitles: [
        { id: 1, sub: "Running Jersey" },
        { id: 2, sub: "Running Shorts" },
        { id: 3, sub: "Running Shoes" },
      ],
    },
  ];
  const chunkedList = [];
  for (let i = 0; i < list.length; i += 3) {
    chunkedList.push(list.slice(i, i + 3));
  }

  const isMounted = useRef(true);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const result = await menuSection();
      console.log("Menu : ", result);
      if (isMounted.current) {
        setMenu(result.data);
      }
    } catch (error) {
      console.error("Error fetching menu:", error);
    }
  };

  const clickHandler = async (name, slug) => {
    try {
      console.log("enter name : ", name);
      setMenuSlugName(slug);
      const formData = new FormData();
      formData.append("slug", name);
      const response = await menuSlugSection(formData);
      console.log("API response:", response.data.category);
      if (response.success === true) {
        setMenuSlug(response.data.category);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // useEffect(() => {
  //   clickProductHandler();
  // }, [menuSlugName,menuSubcategory]);

  // const debouncedClickHandler = useCallback(
  //   debounce((name) => clickProductHandler(name), 300),
  //   [menuSubcategory]
  // );

  // useEffect(() => {
  //   return () => {
  //     debouncedClickHandler.cancel();
  //   };
  // }, [debouncedClickHandler]);

  // const clickProductHandler = async () => {
  //   try {
  //     console.log("menuSlug:", menuSlugName, "categorySlug:", menuSubcategory);
  //     const formData = new FormData();
  //     formData.append("menuSlug", menuSlugName);
  //     formData.append("categorySlug", menuSubcategory);

  //     const response = await productApi(formData);
  //     console.log("API response for product:", response);

  //     if (response.success && response.data) {
  //       setProduct(response.data);

  //       console.log("Navigating to productListing with data:", response.data);
  //       navigate("/productListing", { state: { productData: response.data } });
  //     } else {
  //       console.error("Product API response invalid or empty");
  //       toast.error("Product not found");
  //     }
  //   } catch (error) {
  //     console.error("Error during product API call:", error);
  //   }
  // };

  const clickProductHandler = async (name, menu, categoryName) => {
    if (!menuSlugName || !name) {
      console.error("Invalid slugs provided:", { menuSlugName, name });
      return;
    }

    try {
      console.log("menuSlug:", menuSlugName, "categorySlug:", name);
      console.log("categoryName : ", categoryName);
      const formData = new FormData();
      formData.append("menuSlug", menuSlugName);
      formData.append("categorySlug", name);

      const response = await productApi(formData);
      console.log("API response for product:", response);

      if (response.success && response.data) {
        setProduct(response.data);

        console.log("Navigating to productListing with data:", response.data);
        navigate("/productListing", {
          state: {
            productData: response.data,
            menu: menu,
            categoryName: categoryName,
          },
        });

        // const productData = encodeURIComponent(JSON.stringify(response.data));
        // window.location.href = `/productListing?productData=${productData}`;
      } else {
        console.error("Product API response invalid or empty");
        toast.error("Product not found");
      }
    } catch (error) {
      console.error("Error during product API call:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setClickedItemIndex(null); // Close dropdown
      }
    };

    if (clickedItemIndex !== null) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [clickedItemIndex]);

  return (
    <div>
      <header className="header-main">
        <div className="block-container-2 header-block">
          <div
            className={`align-items-center header-row ${
              isMobileMenuOpen ? "mobile-align-items-center" : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Mobile Menu Button */}
              <div className="d-block d-lg-none">
                <div className="menu-button f_menuActionContainer">
                  <button
                    className={`${
                      isMobileMenuOpen
                        ? "mobile-icon-btn"
                        : "btn nav-main-toggle-btn header-actions-btn"
                    }`}
                    onClick={toggleMobileMenu}
                    aria-label="Menu"
                  >
                    {isMobileMenuOpen ? (
                      <AiOutlineClose
                        className="icon-close"
                        style={{ color: "black" }}
                      />
                    ) : (
                      <RiMenu3Line
                        className="icon-burger-menu"
                        style={{ color: "black" }}
                      />
                    )}
                  </button>
                </div>
              </div>

              {/* Logo Section */}
              <div
                className="header-logo-col"
                style={{
                  // paddingTop: "10px",
                  // paddingBottom: "10px",
                  width: "max-content",
                  position: "relative",
                  margin: "0px",
                }}
              >
                <div className="header-logo-main">
                  <img
                    className="header-logo-img"
                    src="/asset/LogoSportsBlack.png"
                    alt="Sports"
                  />
                  {/* <a
                    className={`header-logo-main-link ${
                      isMobileMenuOpen ? "mobile-header-logo-main-link" : ""
                    }`}
                    onClick={() => navigate("/")}
                    title="Go to homepage"
                    style={{
                      fontSize: "24px",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Shield Sports
                  </a> */}
                </div>
              </div>
            </div>

            {/* Navigation Menu */}
            <div
              className={`${
                isMobileMenuOpen
                  ? "show-mobile-menu header-nav-box "
                  : "header-nav-box "
              }`}
            >
              <div className="main-navigation d-lg-block" id="mainNavigation">
                <div className="container">
                  <nav
                    className="nav main-navigation-menu default"
                    itemScope="itemscope"
                    itemType="http://schema.org/SiteNavigationElement"
                  >
                    <div className="main-navigation-menu-container">
                      {/* <Sports isMobileMenuOpen={isMobileMenuOpen} /> */}

                      <div style={{ width: "100%" }}>
                        <div
                          className="nav-link main-navigation-link"
                          title="Sports"
                          style={{ width: "100%", padding: "0px" }}
                        >
                          <div
                            className={`${
                              isMobileMenuOpen
                                ? "main-navigation-link-text mobile-main-navigation-link-text"
                                : "main-navigation-link-text"
                            }`}
                          >
                            {menu.map((item1, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  // backgroundColor: "white",
                                }}
                                onClick={() => {
                                  // Navigate directly for "Design your own"
                                  if (item1.name === "Design your own") {
                                    window.location.href = "/3d-product";
                                  } else {
                                    clickHandler(item1.name, item1.slug);
                                    setClickedItemIndex(index);
                                  }
                                }}
                                ref={
                                  clickedItemIndex === index
                                    ? dropdownRef
                                    : null
                                }
                              >
                                <span
                                  style={{
                                    // fontFamily: "sans-serif",
                                    color: "black",
                                    fontWeight: "bold",
                                    width: "100%",
                                  }}
                                >
                                  {item1.name}
                                </span>
                                {item1.name !== "Design your own" && (
                                  <AiOutlineDown
                                    className={
                                      clickedItemIndex === index
                                        ? "icon-arrow-down-black"
                                        : "icon-arrow-up-black"
                                    }
                                    color="black"
                                  />
                                )}
                                {item1.name !== "Design your own" &&
                                  clickedItemIndex === index &&
                                  menuSlug.length > 0 && (
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby={`dropdownMenuButton${index}`}
                                      style={{
                                        position: "absolute",
                                        left: "0",
                                        // backgroundColor: "white",
                                        zIndex: 10,
                                        boxShadow:
                                          "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        display: "flex",
                                        // width: "max-content",
                                        height: "393px",
                                        flexDirection: "column",
                                        flexWrap: "wrap",
                                        top: "70px",
                                      }}
                                    >
                                      {menuSlug.map((item) => (
                                        <li
                                          key={item.id}
                                          className="dropdown-item"
                                        >
                                          <div>
                                            <strong
                                              onClick={() => {
                                                setmenuSubcategory(item.slug);
                                                clickProductHandler(
                                                  item.slug,
                                                  item1.slug,
                                                  item.slug
                                                );
                                                // debouncedClickHandler(item.slug);
                                              }}
                                            >
                                              {item.name}
                                            </strong>
                                            <ul className="list-unstyled">
                                              {item.subcategory.map(
                                                (subItem) => (
                                                  <li key={subItem.id}>
                                                    <a
                                                      className="text-decoration-none"
                                                      onClick={() => {
                                                        setmenuSubcategory(
                                                          subItem.slug
                                                        );
                                                        clickProductHandler(
                                                          subItem.slug,
                                                          item1.slug,
                                                          item.slug
                                                        );
                                                        // debouncedClickHandler(subItem.slug);
                                                      }}
                                                    >
                                                      {subItem.name}
                                                    </a>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </li>
                                      ))}
                                      <div className="dropdown-image">
                                        <img
                                          src="https://www.spized.com/thumbnail/26/73/a7/1702032557/navigations-popup_socken-neu_1920x1920.jpg"
                                          alt="Product"
                                          style={{
                                            width: "300px",
                                            height: "300px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </div>
                                    </ul>
                                  )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            {/* Account and Cart Section */}
            <div
              className={`header-action-box ${
                isMobileMenuOpen
                  ? "header-action-box mobile-header-action-box"
                  : ""
              }`}
            >
              <AccountSec />
              <div
                className="header-cart-btn is-opened"
                data-cart-widget="true"
                onClick={() => (window.location.href = "/cart")}
                style={{
                  cursor: "pointer",
                  marginLeft: "30px",
                  width: "25px",
                  height: "29px",
                  position: "relative",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.854"
                  height="26.335"
                  viewBox="0 0 25.854 26.335"
                  style={{ width: "16px" }}
                >
                  <g transform="translate(-3.133 -4.166)">
                    {/* SVG paths */}
                    <path
                      d="M26.25,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,26.25,113.343Z"
                      transform="translate(-17.94 -85.81)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M70,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,70,113.343Z"
                      transform="translate(-53.819 -85.81)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M4.375,12.974,6.819,21.25a1.574,1.574,0,0,0,1.522,1.168H19.693a1.574,1.574,0,0,0,1.525-1.168L24.616,8.5A2.36,2.36,0,0,1,26.9,6.743h1.089"
                      transform="translate(0)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M10,5v9.286M5,10l5,5,5-5"
                      transform="translate(4.375 0.166)"
                      fill="none"
                      stroke="#1a73e9"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                  </g>
                </svg>

                {totalItems > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-13px", 
                      right: "-14px", 
                      backgroundColor: "#ff0000",
                      color: "white",
                      borderRadius: "50%",
                      padding: "0 6px", 
                      fontSize: "12px",
                      fontWeight: "bold", 
                    }}
                  >
                    {totalItems}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;

// import React, { useEffect, useState, useRef } from "react";
// import { AiOutlineClose, AiOutlineDown } from "react-icons/ai";
// import { RiMenu3Line } from "react-icons/ri";
// import { useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import AccountSec from "../HeaderContent/AccountSec";
// import { menuSection } from "../../Services/Operations/ProductAPI";

// function Header() {
//   const navigate = useNavigate();
//   const [menu, setMenu] = useState([]);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

//   const toggleDropdown = (index) => setHoveredItemIndex(index);
//   const closeDropdown = () => setHoveredItemIndex(null);

//   const list = [
//     {
//       cat_id: 1,
//       title: "Badminton",
//       subTitles: [
//         { id: 1, sub: "Badminton Jersey" },
//         { id: 2, sub: "Badminton Shorts" },
//         { id: 3, sub: "Badminton Tank Top" },
//       ],
//     },
//     {
//       cat_id: 2,
//       title: "Football",
//       subTitles: [
//         { id: 1, sub: "Football Jersey" },
//         { id: 2, sub: "Football Shorts" },
//         { id: 3, sub: "Football Shoes" },
//       ],
//     },
//     {
//       cat_id: 3,
//       title: "Basketball",
//       subTitles: [
//         { id: 1, sub: "Basketball Jersey" },
//         { id: 2, sub: "Basketball Shorts" },
//         { id: 3, sub: "Basketball Shoes" },
//       ],
//     },
//   ];
//   const chunkedList = [];
//   for (let i = 0; i < list.length; i += 3) {
//     chunkedList.push(list.slice(i, i + 3));
//   }

//   const isMounted = useRef(true);
//   useEffect(() => {
//     const loadData = async () => {
//       try {
//         const result = await menuSection();
//         if (isMounted.current) {
//           setMenu(result.data);
//         }
//       } catch (error) {
//         console.error("Error fetching menu:", error);
//       }
//     };

//     loadData();
//     return () => {
//       isMounted.current = false;
//     };
//   }, []);

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light">
//       <div className="container-fluid">
//         {/* Logo Section */}
//         <a
//           className="navbar-brand fw-bold"
//           href="#"
//           onClick={() => navigate("/")}
//         >
//           Shield Sports
//         </a>

//         {/* Mobile Toggle Button */}
//         <button
//           className="navbar-toggler"
//           type="button"
//           onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}  // Toggle state on click
//           aria-expanded={isMobileMenuOpen ? "true" : "false"}  // Control aria-expanded based on state
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>

//         {/* Menu Items */}
//         <div
//           className={`collapse navbar-collapse ${isMobileMenuOpen ? "show" : ""}`}  // Conditionally apply 'show' class
//           id="navbarSupportedContent"
//         >
//           <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//             {menu.map((item1, index) => (
//               <li
//                 key={index}
//                 className="nav-item dropdown"
//                 onMouseEnter={() =>
//                   item1.name !== "Design your own" && toggleDropdown(index)
//                 }
//                 onMouseLeave={() =>
//                   item1.name !== "Design your own" && closeDropdown()
//                 }
//               >
//                 <a
//                   className="nav-link dropdown-toggle"
//                   href="#"
//                   id={`dropdownMenuButton${index}`}
//                   role="button"
//                   aria-expanded={hoveredItemIndex === index}  // Control expand with state
//                   onClick={(e) => {
//                     if (item1.name === "Design your own") {
//                       window.location.href = "/3d-product";
//                     } else {
//                       // Prevent Bootstrap's dropdown from handling toggle
//                       e.preventDefault();
//                       toggleDropdown(index);
//                     }
//                   }}
//                 >
//                   {item1.name}
//                 </a>
//                 {hoveredItemIndex === index && item1.name !== "Design your own" && (
//                   <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
//                     {chunkedList.map((chunk, columnIndex) => (
//                       <li key={columnIndex} className="dropdown-item">
//                         {chunk.map((item) => (
//                           <div key={item.cat_id}>
//                             <strong>{item.title}</strong>
//                             <ul className="list-unstyled">
//                               {item.subTitles.map((subItem) => (
//                                 <li key={subItem.id}>
//                                   <a
//                                     href="#"
//                                     className="text-decoration-none"
//                                     onClick={() =>
//                                       window.location.href = "/productListing"
//                                     }
//                                   >
//                                     {subItem.sub}
//                                   </a>
//                                 </li>
//                               ))}
//                             </ul>
//                           </div>
//                         ))}
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </li>
//             ))}
//           </ul>

//           {/* Account Section */}
//           <div className="d-flex">
//             <AccountSec />
//             <div
//               className="ms-3"
//               onClick={() => (window.location.href = "/cart")}
//               style={{ cursor: "pointer" }}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="25.854"
//                 height="26.335"
//                 viewBox="0 0 25.854 26.335"
//                 style={{ width: "16px" }}
//               >
//                 <g transform="translate(-3.133 -4.166)">
//                   <path
//                     d="M26.25,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,26.25,113.343Z"
//                     transform="translate(-17.94 -85.81)"
//                     fill="none"
//                     stroke="#000"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                   ></path>
//                   <path
//                     d="M70,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,70,113.343Z"
//                     transform="translate(-53.819 -85.81)"
//                     fill="none"
//                     stroke="#000"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                   ></path>
//                   <path
//                     d="M4.375,12.974,6.819,21.25a1.574,1.574,0,0,0,1.522,1.168H19.693a1.574,1.574,0,0,0,1.525-1.168L24.616,8.5A2.36,2.36,0,0,1,26.9,6.743h1.089"
//                     transform="translate(0)"
//                     fill="none"
//                     stroke="#000"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                   ></path>
//                 </g>
//               </svg>
//             </div>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Header;

// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import AccountSec from "../HeaderContent/AccountSec";
// import { menuSection } from "../../Services/Operations/ProductAPI";
// import "../../Styles/Header.css";

// function Header() {
//   const navigate = useNavigate();
//   const [menu, setMenu] = useState([]);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [clickedItemIndex, setClickedItemIndex] = useState(null);
//   const dropdownRef = useRef(null);

//   const toggleDropdown = (index) => {
//     setClickedItemIndex((prevIndex) => (prevIndex === index ? null : index));
//   };

//   const handleOutsideClick = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setClickedItemIndex(null); // Close dropdown
//     }
//   };

//   useEffect(() => {
//     const loadData = async () => {
//       try {
//         const result = await menuSection();
//         setMenu(result.data || []); // Set menu data
//       } catch (error) {
//         console.error("Error fetching menu:", error);
//       }
//     };

//     loadData();
//   }, []);

//   useEffect(() => {
//     document.addEventListener("mousedown", handleOutsideClick);
//     return () => {
//       document.removeEventListener("mousedown", handleOutsideClick);
//     };
//   }, []);

//   const sampleList = [
//     {
//       cat_id: 1,
//       title: "Badminton",
//       subTitles: [
//         { id: 1, sub: "Badminton Jersey" },
//         { id: 2, sub: "Badminton Shorts" },
//         { id: 3, sub: "Badminton Tank Top" },
//       ],
//     },
//     {
//       cat_id: 2,
//       title: "Football",
//       subTitles: [
//         { id: 1, sub: "Football Jersey" },
//         { id: 2, sub: "Football Shorts" },
//         { id: 3, sub: "Football Shoes" },
//       ],
//     },
//     {
//       cat_id: 3,
//       title: "Basketball",
//       subTitles: [
//         { id: 1, sub: "Basketball Jersey" },
//         { id: 2, sub: "Basketball Shorts" },
//         { id: 3, sub: "Basketball Shoes" },
//       ],
//     },
//     {
//       cat_id: 4,
//       title: "Tennis",
//       subTitles: [
//         { id: 1, sub: "Tennis Racket" },
//         { id: 2, sub: "Tennis Shorts" },
//         { id: 3, sub: "Tennis Shoes" },
//       ],
//     },
//     {
//       cat_id: 5,
//       title: "Running",
//       subTitles: [
//         { id: 1, sub: "Running Jersey" },
//         { id: 2, sub: "Running Shorts" },
//         { id: 3, sub: "Running Shoes" },
//       ],
//     },
//   ];

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light">
//       <div className="container-fluid">
//         <a
//           className="navbar-brand fw-bold"
//           href="#"
//           onClick={() => navigate("/")}
//           style={{
//             fontSize: "28px",
//             textDecoration: "none",
//             fontWeight: "bold",
//           }}
//         >
//           Shield Sports
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
//           aria-expanded={isMobileMenuOpen ? "true" : "false"}
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div
//           className={`collapse navbar-collapse ${
//             isMobileMenuOpen ? "show" : ""
//           }`}
//           id="navbarSupportedContent"
//         >
//           <ul
//             className="navbar-nav me-auto mb-2 mb-lg-0"
//             ref={dropdownRef}
//             style={{ fontWeight: "800", fontSize: "18px" }}
//           >
//             {menu.map((menuItem, index) => (
//               <li key={index} className="nav-item dropdown">
//                 <a
//                   className="nav-link dropdown-toggle"
//                   href="#"
//                   role="button"
//                   aria-expanded={clickedItemIndex === index}
//                   onClick={(e) => {
//                     if (menuItem.name === "Design your own") {
//                       window.location.href = "/3d-product";
//                     } else {
//                       e.preventDefault();
//                       toggleDropdown(index);
//                     }
//                   }}
//                 >
//                   {menuItem.name}
//                 </a>
//                 {menuItem.name !== "Design your own" &&
//                   clickedItemIndex === index && (
//                     <div
//                       className="dropdown-menu show dropdown-custom"
//                       style={{
//                         width: "max-content",
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "column",
//                           flexWrap: "wrap",
//                           maxHeight: "300px",
//                         }}
//                       >
//                         {sampleList.map((listItem) => (
//                           <div key={listItem.cat_id} className="dropdown-item">
//                             <strong>{listItem.title}</strong>
//                             <ul className="list-unstyled">
//                               {listItem.subTitles.map((subItem) => (
//                                 <li key={subItem.id}>
//                                   <a
//                                     href="#"
//                                     className="text-decoration-none"
//                                     onClick={() => {
//                                       if (menuItem.name === "Design your own") {
//                                         window.location.href = "/3d-product";
//                                       } else {
//                                         window.location.href =
//                                           "/productListing";
//                                       }
//                                     }}
//                                   >
//                                     {subItem.sub}
//                                   </a>
//                                 </li>
//                               ))}
//                             </ul>
//                           </div>
//                         ))}
//                       </div>
//                       <div className="dropdown-image">
//                         <img
//                           src="https://www.spized.com/thumbnail/26/73/a7/1702032557/navigations-popup_socken-neu_1920x1920.jpg"
//                           alt="Product"
//                           style={{
//                             width: "300px",
//                             height: "300px",
//                             objectFit: "cover",
//                           }}
//                         />
//                       </div>
//                     </div>
//                   )}
//               </li>
//             ))}
//           </ul>
//           <li class="nav-item" style={{textDecoration:"none"}}>
//             <a
//               class="nav-link"
//               style={{ fontWeight: "800", fontSize: "18px",textDecoration:"none"}}
//               onClick={() => (window.location.href = "/3d-product")}
//             >

//               Design your own
//             </a>
//           </li>
//           {/* Account and Cart Section */}
//           <div
//             className={`header-action-box ${
//               isMobileMenuOpen
//                 ? "header-action-box mobile-header-action-box"
//                 : ""
//             }`}
//           >
//             <AccountSec />
//             <div
//               className="header-cart-btn is-opened"
//               data-cart-widget="true"
//               onClick={() => (window.location.href = "/cart")}
//               style={{
//                 cursor: "pointer",
//                 marginLeft: "30px",
//                 width: "25px",
//                 height: "29px",
//               }}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="25.854"
//                 height="26.335"
//                 viewBox="0 0 25.854 26.335"
//                 style={{ width: "16px" }}
//               >
//                 <g transform="translate(-3.133 -4.166)">
//                   <path
//                     d="M26.25,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,26.25,113.343Z"
//                     transform="translate(-17.94 -85.81)"
//                     fill="none"
//                     stroke="#000"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                   ></path>
//                   <path
//                     d="M70,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,70,113.343Z"
//                     transform="translate(-53.819 -85.81)"
//                     fill="none"
//                     stroke="#000"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                   ></path>
//                   <path
//                     d="M4.375,12.974,6.819,21.25a1.574,1.574,0,0,0,1.522,1.168H19.693a1.574,1.574,0,0,0,1.525-1.168L24.616,8.5A2.36,2.36,0,0,1,26.9,6.743h1.089"
//                     transform="translate(0)"
//                     fill="none"
//                     stroke="#000"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                   ></path>
//                   <path
//                     d="M10,5v9.286M5,10l5,5,5-5"
//                     transform="translate(4.375 0.166)"
//                     fill="none"
//                     stroke="#1a73e9"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                   ></path>
//                 </g>
//               </svg>
//             </div>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Header;
