import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FaAngleRight } from 'react-icons/fa';

function ProductSlider() {
    const products = [
        {
          id: 'e0c644047c75dcf12413f34032db31cb8fc3896b49f6f76bcc164d58f82edd73f1612d0b635c25b0e8a154c919ae5d1e1b9cfe8cdc51864f0afc2b4ce0df64e6',
          title: "Swish women’s basketball jersey",
          image: "https://www.spized.com/thumbnail/c8/ee/34/1639577454/basketablltrikot-damen-selbst-gestalten-tank-top-loewen-rot-rheinalnd-lions_(1)_800x800.jpg",
         
        },
        {
          id: '358555cbd45a189f283ce021bc712f34d8d78c500c9b9f8aff4fd21383a0a24133c13558dd79ef7a2a4c8236033222f523b635d4b619eb3489fc35c84deb7b03',
          title: "Men’s basketball shooter shirt",
          image: "https://www.spized.com/thumbnail/94/3c/67/1639577454/basketballtrikot-shooter-shirt-selbst-gestalten-bg-biba-sparkasse-rot-blau_(1)_1280x1280.jpg",
         
        },
        {
          id: '123fc4b0b55d1e43da5b95491985f2bebd948dda3d2ba2eeb2667e9b35422a8853c28b7ee7503833a33f1b39b05068f83088ac2ad82fec116924550ab00c8403',
          title: "Viborg men's handball jersey l/s",
          image: "https://www.spized.com/thumbnail/28/6d/67/1639408485/hanballtrikot-langarm-selber-gestalten-osg-fredersdorf-sonnenschutz-langarm-orange-schwarz_1280x1280.jpg",
          
        },
        {
          id: '3c83f338429dcd2f677ce95236925cfee5aba132751b1b6b6aa7c8db7cb8450e2efb066c103f4cdc7bec71b0b79ee9ee44bd6418b41c1451aa95976c33b57fd8',
          title: "Skjern women's handball jersey",
          image: "https://www.spized.com/thumbnail/44/b7/19/1639408478/handballtrikot-selber-gestalten-handball-kurzarm-hsg-refrath-rot_800x800.jpg",
         
        },
        {
          id: 'f5de0a11181233d8fc946a97d8b8ea10b186293869aa4921a87f228ca57bb0809b26c8db5993981a8fb089e191f15304c733698b54e675f1e8fb546f0eb8326d',
          title: "Viborg men's handball jersey",
          image: "https://www.spized.com/bundles/spized/asset/img/no-image.svg?1729673264",
          
        },
        {
          id: '6a65b0a01149ebaa07c3612e911022378a445b834934b29ac5f133678d1206f638a38c568ac14d3f47369d914f093e1e13b42c964d8206ef5cf2a5a8f4660c05',
          title: "Viborg men's handball jersey",
          image: "https://www.spized.com/bundles/spized/asset/img/no-image.svg?1729673264",
          link: "https://www.spized.com/en/detail/141ff43eb05149be93f6d541c6807f23"
        }
      ];

  return (
    <div style={{width:"100%",}}>
    <div className="col-12" data-cms-element-id="01909d40d9827a2aa5d33ff1fdefab27">
      <div className="configurator-product-slider-title">
        <div className="configurator-product-slider-title-inner block-container-2">
          <h3 className="main-title t1">
            Need some inspiration? Explore custom designs from our customers! Click on any design and start 
          </h3>

          <p className="main-description t2">
          personalizing it with our 3D configurator
          </p>
        </div>
      </div>
    </div>
    <div className="block-container-2 configurator-product-slider">
      <Swiper
        modules={[Navigation]} 
        spaceBetween={36}
        slidesPerView={4} 
        navigation
        breakpoints={{
          320: {
            slidesPerView: 1, 
            spaceBetween: 28,
          },
          575: {
            slidesPerView: 2,
            spaceBetween: 28,
          },
          767: {
            slidesPerView: 3,
            spaceBetween: 28,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 36,
          },
          1220: {
            slidesPerView: 4,
            spaceBetween: 36,
          },
        }}
      >
        {products.map(product => (
          <SwiperSlide key={product.id}>
            <div className="product--info bgweb2">
              <a
                className="product--image"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={product.image}
                  className="img-fluid f_lazy"
                  alt={product.title}
                  style={{ width: '100%', height: 'auto' }}
                />
              </a>
              <div className="product--details bghighlightColor">
                <a
                
                  className="product--title t4 morelink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {product.title}
                </a>
                <div className="product--detail-btn">
                  <a
                  
                    className="btn button2 button-secondary"
                    target="_self"
                  >
                    Details
                    <FaAngleRight className="icon-arrow-right-bold"/>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    </div>
  );
}

export default ProductSlider;
