import React, { useState } from "react";
import { useSnapshot } from "valtio";
import state from "./index.js";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

const PositionControl = () => {
  const snap = useSnapshot(state);
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handleTextChange = (type, value) => {
    if (type === "front") {
      state.frontText = value;
    } else if (type === "back") {
      state.backText = value;
    }
  };

  const handlePositionChange = (type, index, value) => {
    console.log("type : ",type)
    console.log("index : ",index)
    console.log("value : ",value)
    if (type === "front") {
      state.frontTextPosition[index] = value;
    } else if (type === "back") {
      state.backTextPosition[index] = value;
    }
    console.log("state.backTextPosition : ",state.backTextPosition)
  };

  const handleNumberChange = (type, value) => {
    if (type === "front") {
      state.frontNumber = value;
    } else if (type === "back") {
      state.backNumber = value;
    }
  };

  const handleNumberPositionChange = (type, index, value) => {
    console.log("type : ",type)
    console.log("index : ",index)
    console.log("value : ",value)
    if (type === "front") {
      state.frontNumberposition[index] = value;
    } else if (type === "back") {
      state.backNumberposition[index] = value;
    }
    console.log("state.frontNumberposition : ",state.frontNumberposition)
  };

  const handleLogoPositionChange = (type, index, value) => {
    console.log("type : ",type)
    console.log("index : ",index)
    console.log("value : ",value)
    if (type === "front") {
      state.frontLogoposition[index] = value;
    } else if (type === "back") {
      state.backLogoposition[index] = value;
    }
    console.log("state.frontLogoposition : ",state.frontLogoposition)
  };

  const handleRotationChange = (type, index, value) => {
    if (type === "front") {
      state.frontTextRotation[index] = value;
    } else if (type === "back") {
      state.backTextRotation[index] = value;
    }
    console.log("state.frontLogoposition : ",state.frontLogoposition)
  };

  const handleNumberRotationChange = (type, index, value) => {
    if (type === "front") {
      state.frontNumberrotation[index] = value;
    } else if (type === "back") {
      state.backNumberrotation[index] = value;
    }
    console.log("state.frontNumberrotation : ",state.frontNumberrotation)
  };

  const handleLogoRotationChange = (type, index, value) => {
    if (type === "front") {
      state.frontLogorotation[index] = value;
    } else if (type === "back") {
      state.backLogorotation[index] = value;
    }
    console.log("state.frontLogorotation : ",state.frontLogorotation)
  };

  const handleScaleChange = (type, index, value) => {
    if (type === "front") {
      state.frontTextScale[index] = value;
    } else if (type === "back") {
      state.backTextScale[index] = value;
    }
  };

  const handleNumberScaleChange = (type, index, value) => {
    if (type === "front") {
      state.frontNumberscale[index] = value;
    } else if (type === "back") {
      state.backNumberscale[index] = value;
    }
    console.log("state.frontNumberscale : ",state.frontNumberscale)
  };

  const handleLogoScaleChange = (type, index, value) => {
    if (type === "front") {
      state.frontLogoscale[index] = value;
    } else if (type === "back") {
      state.backLogoscale[index] = value;
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        gap: "10px",
        top: "0px",
        left: "130px",
        width: "356px",
        height: "95vh",
        backgroundColor: "white",
        zIndex: "100000",
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        overflowY:"auto",
        marginBottom:"20px"
      }}
    >
      <h4 onClick={() => toggleSection("frontText")} style={{display:"flex",alignItems:"center",justifyContent:"space-between",fontSize:"18px",fontWeight:"500", }}>
        Front Text Position Change <span>{openSection === 'frontText' ? <FaAngleUp /> : <FaAngleDown />}</span>
      </h4>
      {openSection === "frontText" && (
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px",}}>
            <span style={{ color: "#4B5563" }}>Front Text:</span>
            <input
              type="text"
              value={snap.frontText}
              onChange={(event) =>
                handleTextChange("front", event.target.value)
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handlePositionChange(
                  "front",
                  0,
                  snap.frontTextPosition[0] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handlePositionChange(
                  "front",
                  0,
                  snap.frontTextPosition[0] + 10
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handlePositionChange(
                  "front",
                  1,
                  snap.frontTextPosition[1] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handlePositionChange(
                  "front",
                  1,
                  snap.frontTextPosition[1] + 10
                )
              }
            >
              +
            </button>
         </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handlePositionChange(
                  "front",
                  2,
                  snap.frontTextPosition[2] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handlePositionChange(
                  "front",
                  2,
                  snap.frontTextPosition[2] + 10
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Rotation X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  1,
                  snap.frontTextRotation[1] - Math.PI
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  1,
                  snap.frontTextRotation[1] + Math.PI
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FRY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  1,
                  snap.frontTextRotation[1] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  1,
                  snap.frontTextRotation[1] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Rotation Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  2,
                  snap.frontTextRotation[2] - Math.PI / 4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  2,
                  snap.frontTextRotation[2] + Math.PI / 4
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Size X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 0, snap.frontTextScale[0] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 0, snap.frontTextScale[0] + 10)
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Size Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 1, snap.frontTextScale[1] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 1, snap.frontTextScale[1] + 10)
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FSZ:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 2, snap.frontTextScale[2] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 2, snap.frontTextScale[2] + 10)
              }
            >
              +
            </button>
          </div> */}
        </div>
      )}
      <h4 onClick={() => toggleSection("backText")} style={{fontSize:"18px",fontWeight:"500", display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        Back Text Position Change <span>{openSection === 'backText' ? <FaAngleUp /> : <FaAngleDown />}</span>
      </h4>
      {openSection === "backText" && (
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", }}>
            <span style={{ color: "#4B5563" }}>Back Text:</span>
            <input
              type="text"
              value={snap.backText}
              onChange={(event) =>
                handleTextChange("back", event.target.value)
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handlePositionChange(
                  "back",
                  0,
                  snap.backTextPosition[0] + 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handlePositionChange(
                  "back",
                  0,
                  snap.backTextPosition[0] - 10
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handlePositionChange(
                  "back",
                  1,
                  snap.backTextPosition[0] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handlePositionChange(
                  "back",
                  1,
                  snap.backTextPosition[0] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handlePositionChange(
                  "back",
                  2,
                  snap.backTextPosition[2] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handlePositionChange(
                  "back",
                  2,
                  snap.backTextPosition[2] + 10
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Rotation X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.backTextRotation[1] - Math.PI
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.backTextRotation[1] + Math.PI
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BRY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.backTextRotation[1] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.backTextRotation[1] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Rotation Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  2,
                  snap.backTextRotation[2] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  2,
                  snap.backTextRotation[2] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Size X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 0, snap.backTextScale[0] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 0, snap.backTextScale[0] + 10)
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Size Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 1, snap.backTextScale[1] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 1, snap.backTextScale[1] + 10)
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BSZ:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 2, snap.backTextScale[2] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 2, snap.backTextScale[2] + 10)
              }
            >
              +
            </button>
          </div> */}
        </div>
      )}
      <h4 onClick={() => toggleSection("frontNumber")} style={{fontSize:"18px",fontWeight:"500", display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        Front Number Position Change <span>{openSection === 'frontNumber' ? <FaAngleUp /> : <FaAngleDown />}</span>
      </h4>
      {openSection === "frontNumber" && (
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px",}}>
            <span style={{ color: "#4B5563" }}>Front Number:</span>
            <input
              type="text"
              value={snap.frontNumber}
              onChange={(event) =>
                handleNumberChange("front", event.target.value)
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "front",
                  0,
                  snap.frontNumberposition[0] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "front",
                  0,
                  snap.frontNumberposition[0] + 10
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "front",
                  1,
                  snap.frontNumberposition[1] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "front",
                  1,
                  snap.frontNumberposition[1] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "front",
                  2,
                  snap.frontNumberposition[2] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "front",
                  2,
                  snap.frontNumberposition[2] + 10
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Rotation X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "front",
                  1,
                  snap.frontNumberrotation[1] - Math.PI
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "front",
                  1,
                  snap.frontNumberrotation[1] + Math.PI
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FRY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  2,
                  snap.frontTextRotation[2] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "front",
                  2,
                  snap.frontTextRotation[2] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Rotation Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "front",
                  2,
                  snap.frontNumberrotation[2] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "front",
                  2,
                  snap.frontNumberrotation[2] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Size X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("front", 0, snap.frontNumberscale[0] - 100)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("front", 0, snap.frontNumberscale[0] + 100)
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Size Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("front", 1, snap.frontNumberscale[1] - 100)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("front", 1, snap.frontNumberscale[1] + 100)
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FSZ:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 2, snap.frontTextScale[2] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("front", 2, snap.frontTextScale[2] + 10)
              }
            >
              +
            </button>
          </div> */}
        </div>
      )}
      <h4 onClick={() => toggleSection("backNumber")} style={{fontSize:"18px",fontWeight:"500", display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        Back Number Position Change <span>{openSection === 'backNumber' ? <FaAngleUp /> : <FaAngleDown />}</span>
      </h4>
      {openSection === "backNumber" && (
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", }}>
            <span style={{ color: "#4B5563" }}>Back Text:</span>
            <input
              type="text"
              value={snap.backNumber}
              onChange={(event) =>
                handleNumberChange("back", event.target.value)
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "back",
                  0,
                  snap.backNumberposition[0] + 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "back",
                  0,
                  snap.backNumberposition[0] - 10
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "back",
                  1,
                  snap.backNumberposition[1] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "back",
                  1,
                  snap.backNumberposition[1] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "back",
                  2,
                  snap.backNumberposition[2] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberPositionChange(
                  "back",
                  2,
                  snap.backNumberposition[2] + 10
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Rotation X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "back",
                  1,
                  snap.backNumberrotation[1] - Math.PI
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "back",
                  1,
                  snap.backNumberrotation[1] + Math.PI
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BRY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.frontNumberrotation[1] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.frontNumberrotation[1] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Rotation Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "back",
                  2,
                  snap.backNumberrotation[2] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberRotationChange(
                  "back",
                  2,
                  snap.backNumberrotation[2] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Size X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("back", 0, snap.frontNumberscale[0] - 100)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("back", 0, snap.frontNumberscale[0] + 100)
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Size Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("back", 1, snap.frontNumberscale[1] - 100)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleNumberScaleChange("back", 1, snap.frontNumberscale[1] + 100)
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BSZ:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 2, snap.frontNumberscale[2] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleScaleChange("back", 2, snap.frontNumberscale[2] + 10)
              }
            >
              +
            </button>
          </div> */}
          </div>
        )}

          <h4 onClick={() => toggleSection("frontLogo")} style={{fontSize:"18px",fontWeight:"500", display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            Front Logo Position Change <span>{openSection === 'frontLogo' ? <FaAngleUp /> : <FaAngleDown />}</span>
          </h4>
          {openSection === "frontLogo" && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "front",
                  0,
                  snap.frontLogoposition[0] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "front",
                  0,
                  snap.frontLogoposition[0] + 10
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "front",
                  1,
                  snap.frontLogoposition[1] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "front",
                  1,
                  snap.frontLogoposition[1] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "front",
                  2,
                  snap.frontLogoposition[2] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "front",
                  2,
                  snap.frontLogoposition[2] + 10
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Rotation X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "front",
                  0,
                  snap.frontLogorotation[0] - Math.PI
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "front",
                  0,
                  snap.frontLogorotation[0] + Math.PI
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Rotation Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "front",
                  2,
                  snap.frontLogorotation[2] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "front",
                  2,
                  snap.frontLogorotation[2] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FRZ:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "front",
                  2,
                  snap.frontLogorotation[2] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "front",
                  2,
                  snap.frontLogorotation[2] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Size X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("front", 0, snap.frontLogoscale[0] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("front", 0, snap.frontLogoscale[0] + 10)
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Front Size Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("front", 1, snap.frontLogoscale[1] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("front", 1, snap.frontLogoscale[1] + 10)
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>FSZ:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("front", 2, snap.frontLogoscale[2] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("front", 2, snap.frontLogoscale[2] + 10)
              }
            >
              +
            </button>
          </div> */}
        </div>
      )}

      <h4 onClick={() => toggleSection("backLogo")} style={{fontSize:"18px",fontWeight:"500", display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        Back Logo Position Change <span>{openSection === 'backLogo' ? <FaAngleUp /> : <FaAngleDown />}</span>
      </h4>
      {openSection === "backLogo" && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "back",
                  0,
                  snap.backLogoposition[0] + 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "back",
                  0,
                  snap.backLogoposition[0] - 10
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "back",
                  1,
                  snap.backLogoposition[1] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "back",
                  1,
                  snap.backLogoposition[1] + 10
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
                marginLeft: "10px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "back",
                  2,
                  snap.backLogoposition[2] - 10
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoPositionChange(
                  "back",
                  2,
                  snap.backLogoposition[2] + 10
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Rotation X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "back",
                  0,
                  snap.backLogorotation[0] - Math.PI
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "back",
                  0,
                  snap.backLogorotation[0] + Math.PI
                )
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BRY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.backLogorotation[1] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleRotationChange(
                  "back",
                  1,
                  snap.backLogorotation[1] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Rotation Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "back",
                  2,
                  snap.backLogorotation[2] - Math.PI/4
                )
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoRotationChange(
                  "back",
                  2,
                  snap.backLogorotation[2] + Math.PI/4
                )
              }
            >
              +
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Size X-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("back", 0, snap.backLogoscale[0] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("back", 0, snap.backLogoscale[0] + 10)
              }
            >
              +
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>BSY:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("back", 1, snap.backLogoscale[1] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("back", 1, snap.backLogoscale[1] + 10)
              }
            >
              +
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "max-content",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#4B5563" }}>Back Size Y-axios:</span>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("back", 2, snap.backLogoscale[2] - 10)
              }
            >
              -
            </button>
            <button className="button-style"
              style={{
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                padding: "8px",
              }}
              onClick={() =>
                handleLogoScaleChange("back", 2, snap.backLogoscale[2] + 10)
              }
            >
              +
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PositionControl;
