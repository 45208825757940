import React from "react";

function Description() {
  return (
    <div
      className="tab-pane active f_detail-tab-panel"
      id="description-tab-panel"
      role="tabpanel"
      aria-labelledby="description-tab"
    >
      <div className="offcanvas-body">
        <div className="offcanvas-content-container product-detail-description tab-pane-container">
          <div className="h3 product-detail-description-title" style={{ color: "black", fontWeight: "bold" }}>
            Product information "Rio men’s football jersey"
          </div>
          <div
            className="product-detail-description-text"
            itemprop="description"
          >
            <p>
              The Rio men’s football jersey enjoys a classic cut and will win
              you over with its extraordinary comfort. It is the all-rounder for
              the football pitch and extremely popular with our customers. Our{" "}
              <a href="../../materials.html#CIO-TEX-135">
                CIO-TEX functional fibre
              </a>{" "}
              is made of 100% polyester and is characterised by its extreme
              robustness and outstanding moisture wicking. This keeps you
              pleasantly dry even when you exert yourself considerably. Thanks
              to its excellent price-performance ratio, the Rio men’s football
              jersey is the right choice, especially for demanding and
              price-conscious football teams.&nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
