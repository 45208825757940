import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgetPasswardChange } from "../../Services/Operations/ProductAPI";
import { toast } from "react-toastify";

const ForgotPassward = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      console.log("email : ", email);
      const formData = new FormData();
      formData.append("email", email);
      const response = await forgetPasswardChange(formData);
      console.log("Forget password response: ", response);

      if (response.success) {
        setMessage(response.message);
        toast(response.message);
      } else {
        setMessage("Failed to send password reset email. Please try again.");
      }
    } catch (error) {
      console.error("Error in forgot password: ", error);
      setMessage("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      background: "linear-gradient(to right, #e3f2fd, #bbdefb)",
      // fontFamily: "Arial, sans-serif",
      margin: 0,
    },
    card: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "30px",
      width: "100%",
      maxWidth: "400px",
      boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#333",
    },
    text: {
      fontSize: "14px",
      color: "#666",
      marginBottom: "20px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    input: {
      padding: "10px",
      fontSize: "16px",
      border: "1px solid #ddd",
      borderRadius: "5px",
      marginBottom: "15px",
      transition: "border 0.3s",
    },
    inputFocus: {
      borderColor: "#4facfe",
    },
    button: {
      background: "linear-gradient(to right, #4facfe, #00f2fe)",
      color: "white",
      border: "none",
      padding: "10px 15px",
      fontSize: "16px",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background 0.3s",
    },
    buttonHover: {
      background: "linear-gradient(to right, #00f2fe, #4facfe)",
    },
    message: {
      marginTop: "15px",
      color: "#333",
      fontSize: "14px",
    },
    backButton: {
      background: "none",
      border: "none",
      color: "#4facfe",
      fontSize: "14px",
      marginTop: "20px",
      cursor: "pointer",
    },
    backButtonHover: {
      textDecoration: "underline",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Forgot Password</h2>
        <p style={styles.text}>
          Enter your email address and we'll send you a link to reset your
          password.
        </p>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            style={styles.input}
          />
          <button
            type="submit"
            disabled={loading}
            style={{
              ...styles.button,
              ...(loading ? { cursor: "not-allowed", opacity: 0.7 } : {}),
            }}
          >
            {loading ? "Sending..." : "Send Reset Link"}
          </button>
          {message && <p style={styles.message}>{message}</p>}
        </form>
        <button onClick={() => navigate("/auth")} style={styles.backButton}>
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default ForgotPassward;
