import React from "react";

function Colors() {
  const headingStyle = {
    // fontFamily: "sans-serif",
    color: "black",
    fontWeight: "bold",
  };

  const descriptionStyle = {
    // fontFamily: "sans-serif",
    color: "black",
  };

  const colorItems = [
    { name: "Citrus Yellow (PMS386C)", hex: "#e9ec6b" },
    { name: "Lemon Spized (PMSYellowC)", hex: "#fedd00" },
    { name: "Yellow Sun Flower (PMS116C)", hex: "#ffcd00" },
    { name: "Orange (PMS1235C)", hex: "#ffb81c" },
    { name: "Light Orange (PMS151C)", hex: "#ff8200" },
    { name: "Orange Spized (PMS021C)", hex: "#fe5000" },
    { name: "Warm Red (PMSWARM REDC)", hex: "#f9423a" },
    { name: "Red Spized (PMS185C)", hex: "#e4002b" },
    { name: "Red Wine (PMS187C)", hex: "#a6192e" },
    { name: "Deep Red (PMS199C)", hex: "#d50032" },
    { name: "Burgundy Spized (PMS222C)", hex: "#6c1d45" },
    { name: "Rubine Red (PMSRUBIN REDC)", hex: "#ce0058" },
    { name: "Baby Pink (PMS182C)", hex: "#fabbcb" },
    { name: "Pink (PMS213C)", hex: "#e31c79" },
    { name: "Lavanda (PMS2655C)", hex: "#9678d3" },
    { name: "Violet (PMS268C)", hex: "#582c83" },
    { name: "Liliac (PMS514C)", hex: "#d48bc8" },
    { name: "Smooth Viola (PMS513C)", hex: "#93328e" },
    { name: "Petrol (PMS314C)", hex: "#007fa3" },
    { name: "Mint Green (PMS338C)", hex: "#6eceb2" },
    { name: "Light Blue (PMS278C)", hex: "#8bb8e8" },
    { name: "Royal Spized (PMS2945C)", hex: "#004c97" },
    { name: "Deep Blue (PMS2746C)", hex: "#171c8f" },
    { name: "Navy Blue (PMS2955C)", hex: "#003865" },
    { name: "Powder Blue (PMS2905C)", hex: "#8dc8e8" },
    { name: "Sky Spized (PMS2985C)", hex: "#5bc2e7" },
    { name: "Sea Green (PMS326C)", hex: "#00b2a9" },
    { name: "Briliant Blue (PMS2935C)", hex: "#0057b7" },
    { name: "Blue Marine (PMS296C)", hex: "#051c2c" },
    { name: "Rust (PMS1675C)", hex: "#a9431e" },
    { name: "Lime Green (PMS387C)", hex: "#e3e935" },
    { name: "Chartreuse (PMS368C)", hex: "#78be21" },
    { name: "Green Spized (PMS356C)", hex: "#007a33" },
    { name: "Emerald (PMS3278C)", hex: "#009b77" },
    { name: "Smaragd (PMS342C)", hex: "#006747" },
    { name: "Deep Forest (PMS330C)", hex: "#00534c" },
    { name: "Gold (PMS1245C)", hex: "#c69214" },
    { name: "Sand (PMS7501C)", hex: "#d9c89d" },
    { name: "Antique Gold (PMS4515C)", hex: "#b3a369" },
    { name: "Toffee Brown (PMS462C)", hex: "#5c462b" },
    { name: "Khaki (PMS384C)", hex: "#949300" },
    { name: "Rifle Green (PMS5747C)", hex: "#3d441e" },
    { name: "Warm Gray (WarmGray2C)", hex: "#cbc4bc" },
    { name: "Silver (PMS428C)", hex: "#c1c6c8" },
    // Add more colors if needed
  ];

  return (
    <div>
      <div
        className="tab-pane f_detail-tab-panel"
        id="colors-tab-panel"
        role="tabpanel"
        aria-labelledby="colors-tab"
      >
        <div className="offcanvas-body">
          <div className="colors-tab">
            <h2 className="tab-content--title h3" style={headingStyle}>
              Colours and colour combinations
            </h2>
            <div className="tab-content--description default" style={descriptionStyle}>
              You choose the colours for your garments yourself. As a basis, we
              have put together a palette of 48 standard colours with which you
              can colour your designs. To help you, we have also given you the
              corresponding Pantone Matching System (Coated Palette) number (PMS
              number) for each colour.
            </div>
            <div className="tab-content--description default" style={descriptionStyle}>
              If you have a special colour in mind that is not in our colour
              palette, please let us know by email. We will then transfer the
              colour to your design.
            </div>
            <ul className="color-items" style={styles.colorItems}>
              {colorItems.map((color, index) => (
                <li key={index} className="color-item" style={styles.colorItem}>
                  <a
                    style={{ backgroundColor: color.hex, ...styles.colorCode }}
                    className="color-code"
                  >
                    &nbsp;
                  </a>
                  <p className="color-name">{color.name}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

// Inline styles for the color items
const styles = {
  colorItems: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between", // Adjust spacing as necessary
    listStyleType: "none",
    padding: 0,
    margin: 0,
    marginTop:"20px"
  },
  colorItem: {
    width: "calc(10.5% - 10px)", // Adjust to fit 6-8 items per row; (100%/8) with spacing
    margin: "5px",
    boxSizing: "border-box",
  },
  colorCode: {
    display: "block",
    width: "100%",
    height: "150px", // Adjust height as necessary
    borderRadius: "4px", // Optional: for rounded corners
  },
};

export default Colors;
