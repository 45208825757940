// import { proxy } from 'valtio';

// const state = proxy({
//   intro: true,
//   color: '#EFBD48',
//   backColor: "#ffffff", 
//   frontColor: "#ffffff",
//   fontColor: "#000000",
//   isFrontLogoTexture: true,
//   isBackLogoTexture: true,
//   isFrontText: true,
//   isBackText: true,
//   isFullTexture: false,
//   frontLogoDecal: './threejs.png',
//   fullDecal: './texture.jpeg',
//   frontLogoPosition: [ 0, 0.04, 0.15 ],
//   frontLogoScale: 0.15,
//   backLogoDecal: './threejs.png',
//   backLogoPosition: [0, 0.04, -0.15],
//   backLogoRotation: [0, Math.PI, 0],
//   backLogoScale: 0.15,
//   frontText: 'Front Text',
//   frontTextPosition: [0, -0.04, 0.15],
//   frontTextRotation: [0, 0, 0],
//   frontTextFontSize: 16,
//   frontTextScale: [0.15, 0.04, 0.1],
//   frontTextFont: 'Arial',
//   frontTextSize: 64,
//   frontTextColor: 'black',
//   backText: 'Back Text',
//   backTextPosition: [0, -0.04, -0.15],
//   backTextRotation: [0, Math.PI, 0],
//   backTextFontSize: 16,
//   backTextScale: [0.15, 0.04, 0.1],
//   backTextFont: 'Arial',
//   backTextSize: 64,
//   backTextColor: 'white',
// });

// export default state;

import { proxy } from "valtio"; // Ensure proxy is imported from valtio

const state = proxy({
  intro: true,
  color: "#EFBD48",
  frontText: "",
  backText: "",
  frontNumber: "",
  backNumber: "",
  frontTextPosition:[0, 100, 50],
  frontTextRotation:[Math.PI / 2, Math.PI, Math.PI],
  frontTextScale:[1200, 300, 200],
  frontNumberposition:[0, 100, -30],
  frontNumberrotation:[Math.PI / 2, Math.PI, Math.PI],
  frontNumberscale:[1200, 300, 200],
  frontLogoposition:[70, 100, -140],
  frontLogorotation:[Math.PI / 2, Math.PI, Math.PI],
  frontLogoscale:[100, 100, 100],
  backTextPosition:[0, -100, 50],
  backTextRotation:[Math.PI / 2, 0, Math.PI],
  backTextScale:[1200, 300, 200],
  backNumberposition:[0, -100, -30],
  backNumberrotation:[Math.PI / 2, 0, Math.PI],
  backNumberscale:[1200, 300, 200],
  backLogoposition:[0, -100, -140],
  backLogorotation:[Math.PI / 2, 0, Math.PI],
  backLogoscale:[100, 100, 100],
});

export default state;
