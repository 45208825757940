import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  items: Cookies.get("items") ? JSON.parse(Cookies.get("items")) : [],
  totalItems: Cookies.get("totalItem") ? Number(Cookies.get("totalItem")) : 0,
  configUrl: Cookies.get("configUrl") || "",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ConfigUrl: (state, action) => {
      console.log("state.configUrl : ", action.payload);
      state.configUrl = action.payload;
      Cookies.set("configUrl", state.configUrl);
    },

    addCart: (state, action) => {
      console.log("Adding product:", action.payload);
    
      const existingProduct = state.items.find((item) => item.id === action.payload.id);
    
      if (existingProduct) {
        console.log("Product exists:", existingProduct);
        
        existingProduct.sizes.push({
          size_id: action.payload.size_id,
          size_name: action.payload.size_name,
          quantity: 1,
          price: action.payload.price,
        });
      } else {
        console.log("Adding new product to cart...");
        
        state.items = [
          ...state.items,
          {
            id: action.payload.id,
            name: action.payload.name,
            gallery: action.payload.gallery,
            slug: action.payload.slug,
            price: action.payload.price,
            glbFile: action.payload.glb,
            glbImage: action.payload.image,
            sizes: [
              {
                size_id: action.payload.size_id,
                size_name: action.payload.size_name,
                quantity: 1,
                price: action.payload.price,
              },
            ],
          },
        ];
      }
    
      state.totalItems += 1;
    
      console.log("Before updating cookies, items:", state.items);
      Cookies.set("items", JSON.stringify(state.items));
      Cookies.set("totalItem", state.totalItems);
      console.log("Updated cookies:", Cookies.get("items"));
    
      // Temporary: Also store in localStorage for debugging
      localStorage.setItem("cartItems", JSON.stringify(state.items));
      console.log("Stored in localStorage:", localStorage.getItem("cartItems"));
    },
    

    removeCart: (state, action) => {
      const { productId, rowIndex } = action.payload;
      const product = state.items.find((item) => item.id === productId);

      if (product) {
        const removedItemQuantity = product.sizes[rowIndex].quantity; 
        product.sizes.splice(rowIndex, 1);
        if (product.sizes.length === 0) {
          state.items = state.items.filter((item) => item.id !== productId);
        }
        state.totalItems -= removedItemQuantity;
        Cookies.set("items", JSON.stringify(state.items));
        Cookies.set("totalItem", state.totalItems);
      }
    },

    updateCartQuantity: (state, action) => {
      console.log("Updating quantity: ", action.payload);
      const { id, rowIndex, quantity, size_id } = action.payload;
      console.log("Ustate.items: ", state.items);
    
      const existingProduct = state.items.find((item) => item.id === id);
      console.log("existingProduct: ", existingProduct);
      console.log("Rana")
    
      if (existingProduct) {
        let sizeItem = null;
        let targetIndex = rowIndex;
        console.log("Don",rowIndex)
        console.log("Don1",size_id)
    
        if (targetIndex === undefined && size_id !== undefined) {
          console.log("Finding last occurrence of size_id:", size_id);
          console.log("Saifu")
    
          // Find all indexes of matching size_id
          const matchingIndexes = existingProduct.sizes
            .map((size, index) => ({ ...size, index }))
            .filter(size => size.size_id === size_id)
            .map(size => size.index);
    
          if (matchingIndexes.length > 0) {
            targetIndex = matchingIndexes.pop(); // Get last index
          }
    
          console.log("Auto-detected rowIndex:", targetIndex);
        }
    
        if (targetIndex !== undefined && existingProduct.sizes[targetIndex]) {
          sizeItem = existingProduct.sizes[targetIndex];
        }
    
        if (sizeItem) {
          state.totalItems += quantity - sizeItem.quantity;
          sizeItem.quantity = quantity;
        }
      }
    
      Cookies.set("items", JSON.stringify(state.items));
      Cookies.set("totalItem", state.totalItems);
    },       

    updateCartSize: (state, action) => {
      const { productId, rowIndex, newSizeId } = action.payload;
      const product = state.items.find((item) => item.id === productId);

      if (product && product.sizes[rowIndex]) {
        product.sizes[rowIndex].size_id = newSizeId;
      }

      Cookies.set("items", JSON.stringify(state.items));
    },

    deleteAllCart: (state) => {
      console.log("Deleting all cart items...");
      state.items = [];
      state.totalItems = 0;

      Cookies.set("items", JSON.stringify(state.items));
      Cookies.set("totalItem", state.totalItems);

      console.log("All cart items cleared. Cookies updated.");
    },
    updateCartItem: (state, action) => {
      console.log("Name and Jersey Number added:", action.payload);
      console.log("Value :", action.payload.value);
      const { itemId, sizeIndex, field, value } = action.payload;
    
      const item = state.items.find((i) => i.id === itemId);
    
      if (item) {
        if (item.sizes[sizeIndex]) {
          console.log("Don : ",item.sizes)
          item.sizes[sizeIndex][field] = value; 
        }
      }
      Cookies.set("items", JSON.stringify(state.items));
    },
    
    
  },
});

export const {
  addCart,
  removeCart,
  updateCartQuantity,
  deleteAllCart,
  updateCartSize,
  ConfigUrl,
  updateCartItem
} = cartSlice.actions;

export default cartSlice.reducer;
