import React from 'react'
import { AiFillHome } from 'react-icons/ai'
import { FaAngleRight, FaFacebookSquare, FaInstagram, FaShippingFast } from 'react-icons/fa'
import { BsFillPrinterFill } from "react-icons/bs";
import { GiLeatherArmor } from "react-icons/gi";
import { LuThumbsUp } from "react-icons/lu";

function Footer() {
  return (
    <div>
        <footer className="footer-main bghighlight">
        <div
          className="container block-container-1"
          id="footerColumns"
          data-collapse-footer="true"
        >
          <div className="footer-services">
            <div className="footer-services-item" >
              <BsFillPrinterFill style={{marginRight:"10px", fontSize:"30px"}}/>
              All printing costs <br /> included in price
            </div>
            <div className="footer-services-item" >
              <GiLeatherArmor style={{marginRight:"10px", fontSize:"30px"}}/>
              Professional quality <br /> Made in the EU
            </div>
            <div className="braker"></div>
            <div className="footer-services-item" >
              <LuThumbsUp style={{marginRight:"10px", fontSize:"30px"}}/>
              No minimum purchase <br />– re-orders always possible
            </div>
            <div className="footer-services-item" >
              <FaShippingFast style={{marginRight:"10px", fontSize:"30px"}}/>
              Fast
              <br />
              delivery times
            </div>
          </div>
          <div className="footer-border"></div>
          <div className="footer-columns">
            <div className="footer-column js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitle1"
                aria-expanded="true"
                aria-controls="collapseFooter1"
              >
                Information
                
              </div>
              <div
                id="collapseFooter1"
                className="footer-column-content js-footer-column-content"
                aria-labelledby="collapseFooterTitle1"
              >
                <div className="footer-column-content-inner">
                  <ul className="list-unstyled">
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                 
                        title="contact form"
                        
                      >
                        contact form
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                 
                        title="Legal notices"
                        
                      >
                        Legal notices
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a className="footer-link"  title="GTC" >
                        GTC
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        
                        title="data privacy"
                        
                      >
                        data privacy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitle2"
                aria-expanded="true"
                aria-controls="collapseFooter2"
              >
                Types of sport
                
              </div>
              <div
                id="collapseFooter2"
                className="footer-column-content js-footer-column-content"
                aria-labelledby="collapseFooterTitle2"
              >
                <div className="footer-column-content-inner">
                  <ul className="list-unstyled">
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Football"
                        
                      >
                        Football
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Handball"
                        
                      >
                        Handball
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Basketball"
                        
                      >
                        Basketball
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Winter sports"
                        
                      >
                        Winter sports
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        
                        title="Darts"
                        
                      >
                        Darts
                      </a>
                    </li>
                    <li
                      className="footer-link-item"
                      data-show-cms-modal="true"
                      data-cms-modal-id="0e9ee1f578ab4a3890b94947250a6521"
                    >
                      <span className="footer-link cms-overlay-link" style={{textDecoration:"underline"}}>
                        All sports
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitle3"
                aria-expanded="true"
                aria-controls="collapseFooter3"
              >
                Shop service
                
              </div>
              <div
                id="collapseFooter3"
                className="footer-column-content js-footer-column-content"
                aria-labelledby="collapseFooterTitle3"
              >
                <div className="footer-column-content-inner">
                  <ul className="list-unstyled">
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="FAQ"
                        
                      >
                        FAQ
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        href="en/special-design.html"
                        title="Special design"
                        
                      >
                        Special design
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        
                        title="Sample order"
                        
                      >
                        Sample order
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Information for clubs"
                        
                      >
                        Information for clubs
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Quotation calculator"
                        
                      >
                        Quotation calculator
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="braker"></div>
            <div className="footer-column js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitle4"
                aria-expanded="true"
                aria-controls="collapseFooter4"
              >
                About spized
                
              </div>
              <div
                id="collapseFooter4"
                className="footer-column-content js-footer-column-content"
                aria-labelledby="collapseFooterTitle4"
              >
                <div className="footer-column-content-inner">
                  <ul className="list-unstyled">
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        
                        title="Our vision"
                        
                      >
                        Our vision
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        
                        title="Benefits"
                        
                      >
                        Benefits
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Customers feedback "
                        
                      >
                        Customers feedback
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Jobs"
                        
                      >
                        Jobs
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitle5"
                aria-expanded="true"
                aria-controls="collapseFooter5"
              >
                Purchase order
                
              </div>
              <div
                id="collapseFooter5"
                className="footer-column-content js-footer-column-content"
                aria-labelledby="collapseFooterTitle5"
              >
                <div className="footer-column-content-inner">
                  <ul className="list-unstyled">
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="How do I create my design?"
                        
                      >
                        How do I create my design?
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Order process &amp; delivery time"
                        
                      >
                        Order process &amp; delivery time
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        title="Help in the 3D Configurator"
                        
                      >
                        Help in the 3D Configurator
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        
                        title="Help with the order"
                        
                      >
                        Help with the order
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitleSocial"
                aria-expanded="true"
                aria-controls="collapseFooterSocial"
              >
                Social Media
               
              </div>
              <div
                id="collapseFooterSocial"
                className="footer-column-content js-footer-column-content"
                aria-labelledby="collapseFooterTitleSocial"
              >
                <div className="footer-column-content-inner">
                  <ul className="social-links">
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                       
                        
                      >
                        <FaFacebookSquare style={{marginRight:"10px"}} />
                        Facebook
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        
                        
                      >
                        <FaInstagram style={{marginRight:"10px"}}/>
                        Instagram
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-border step-border"></div>
          {/* <div className="row footer-step-box">
            <div className="footer-column col-md-6 js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitleNewsletter"
                aria-expanded="true"
                aria-controls="collapseFooterNewsletter"
              >
                Newsletter
                
              </div>
              <div
                id="collapseFooterNewsletter"
                className="footer-column-content js-footer-column-content"
                aria-labelledby="collapseFooterTitleNewsletter"
              >
                <div className="footer-column-content-inner">
                  <div className="cms-block newsletter-block">
                    <div className="cms-element-form newsletter-form-content">
                      <form
                        action="https://www.spized.com/en/form/newsletter"
                        method="post"
                        className="newsletter-form js--newsletter-form"
                        data-newsletter-confirm-page="https://www.spized.com/en/newsletter-confirm"
                        data-form-csrf-handler="true"
                        data-form-validation="true"
                      >
                        <div className="js--inner-loader d-none loader-container">
                          <div className="loader-container-box">
                            <div className="loader"></div>
                          </div>
                        </div>
                        <div className="newsletter-form-box form-row">
                          <div className="form-group col-md-6">
                            <div className="form-group col-12">
                              <label className="form-label" for="form-email" >
                                Email address*{" "}
                              </label>
                              <input
                                name="email"
                                type="email"
                                id="form-email"
                                placeholder="Your email address"
                                required="required"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <button
                              type="submit"
                              className="newsletter-form-btn btn btn-outline button2"
                            >
                              Subscribe to newsletter
                            </button>
                          </div>
                        </div>
                        <div
                          className="captcha-google-re-captcha-v3"
                          data-google-re-captcha-v3="true"
                          data-google-re-captcha-v3-options='{"siteKey":"6Lfq5cspAAAAAHRf5ts3bwfvSiJTs8Te6sTphLHL"}'
                        >
                          <input
                            type="text"
                            className="d-none grecaptcha_v3-input"
                            name="_grecaptcha_v3"
                            data-skip-report-validity="true"
                            required
                          />
                          <div className="data-protection-information grecaptcha-protection-information" >
                            This site is protected by reCAPTCHA and the Google{" "}
                            <a href="https://policies.google.com/privacy" style={{textDecoration:"underline"}}>
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a href="https://policies.google.com/terms" style={{textDecoration:"underline"}}>
                              Terms of Service
                            </a>{" "}
                            apply. Unsubscribing the Newsletter is possible at
                            any time.
                          </div>
                        </div>
                        <p className="footer-privacy-text"></p>
                        <div className="form-hidden-fields">
                          <input
                            type="submit"
                            className="submit--hidden d-none"
                          />
                          <input
                            type="hidden"
                            name="option"
                            value="subscribe"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="footer-column col-md-6 js-footer-column">
              <div
                className="t2spec footer-column-headline footer-headline js-collapse-footer-column-trigger"
                data-bs-target="#collapseFooterTitlePayment"
                aria-expanded="true"
                aria-controls="collapseFooterPayment"
              >
                Payment options
                
              </div>
              <div
                id="collapseFooterPayment"
                aria-labelledby="collapseFooterTitlePayment"
              >
                <div className="footer-column-content-inner">
                  <ul className="payment-methods">
                    <li className="master-card-item bgweb2"></li>
                    <li className="visa-item bgweb2"></li>
                    <li className="paypal-item bgweb2"></li>
                    <li className="sofort-item bgweb2"></li>
                    <li className="bank-item bgweb2"> Bank Transfer</li>
                  </ul>
                </div>
              </div>
            </div> 
          </div> */}
        </div>
        <div className="footer-bottom block-container-1">
          <div className="footer-border"></div>
          <div className="row footer-bottom-inner" style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
            <div className="col-md-6 small" style={{width:"maxContent"}}>
              (c) 2024 Shield Sports
            </div>
            {/* <div className="footer-logo-block">
              <a className="footer-logo-box" >
              <AiFillHome className="icon-spized-minimal-logo"/>
                <FaAngleRight className="icon-arrow-right-bold"/>
              </a>
              <a className="footer-breadcrumb-box" >
                Home page
              </a>
            </div> */}
            <div>
            <p style={{width:"maxContent"}}>Made with by siance software pvt ltd</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer