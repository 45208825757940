// import React from "react";

// function Configurator() {
//   const items = [
//     {
//       imgSrc: "https://www.spized.com/media/f5/cc/e1/1695287230/pfeile.svg",
//       title: "Endless Customization Options",
//       description:
//         "Go beyond jerseys! Design shorts, hoodies, tracksuits, and more—all in one place.",
//     },
//     {
//       imgSrc:
//         "https://www.spized.com/media/19/26/7b/1699354877/nachbestellungen-jederzeit-moeglich.svg",
//       title: "Order Any Quantity—No Minimums!",
//       description:
//         "Whether you need one piece or a full team set, we've got you covered. Plus, enjoy locked-in pricing on repeat orders.",
//     },
//     {
//       imgSrc:
//         "https://www.spized.com/media/28/d2/6a/1639408457/attraktive-rabatte.svg",
//       title: "All-Inclusive Pricing",
//       description:
//         "No hidden fees—printing costs are included, no matter how many design elements you add.",
//     },
//   ];

//   const formatText = (text) => {
//     return text.replace(/('|–|—|-)/g, (match) => `<span class="highlight">${match}</span>`);
//   };  

//   return (
//     <div>
//       <div
//         className="cms-section bg-color pos-2 cms-section-default"
//         style={{ backgroundColor: "#f4f4f4" }}
//       >
//         <div className="cms-section-default full-width">
//           <div className="cms-block block-container-2 pos-0 cms-block-spized-advanced-usps">
//             <div
//               className="cms-block-container"
//               style={{ padding: "40px 0 0 0" }}
//             >
//               <div className="cms-block-container-row row cms-row">
//                 <div className="col-12">
//                   <div className="spized-advanced-usps-element">
//                     <div className="spized-advanced-usps-inner block-container">
//                       <h2 className="t1">
//                         Why You<span style={{ fontFamily: "Arial" }}>'</span>ll
//                         Love Our 3D Configurator
//                       </h2>
//                       <div
//                         className="image-text-slider-element cms-element-spized-advanced-usps"
//                         style={{
//                           display: "flex",
//                           flexWrap: "wrap",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         {items.map((item, index) => (
//                           <div
//                             key={index}
//                             className="usp-list-item"
//                             style={{
//                               flex: "1 1 calc(33.33% - 20px)",
//                               margin: "10px",
//                             }}
//                           >
//                             <div className="usp-list-wrapper">
//                               <div className="image-container">
//                                 <img
//                                   src={item.imgSrc}
//                                   className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
//                                   width="50"
//                                   height="50"
//                                   alt={item.title}
//                                 />
//                               </div>
//                               <div className="info-block">
//                                 <div
//                                   className="title t2"
//                                   dangerouslySetInnerHTML={{
//                                     __html: formatText(item.title),
//                                   }}
//                                 />
//                                 <div
//                                   className="description"
//                                   dangerouslySetInnerHTML={{
//                                     __html: formatText(item.description),
//                                   }}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         ))}
//                       </div>
//                       <div className="button-container">
//                         <a
//                           className="btn button2 usps-button"
//                           href="en/benefits.html"
//                           style={{
//                             backgroundColor: "#000000",
//                             color: "#fff",
//                             padding: "10px 20px",
//                             textDecoration: "none",
//                             borderRadius: "5px",
//                           }}
//                         >
//                           Your benefits at a glance
//                         </a>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Configurator;

import React from "react";

function Configurator() {
  return (
    <div>
      <div
        className="cms-section bg-color pos-2 cms-section-default"
        style={{ backgroundColor: "#f4f4f4" }}
      >
        <div className="cms-section-default full-width">
          <div className="cms-block block-container-2 pos-0 cms-block-spized-advanced-usps">
            <div
              className="cms-block-container"
              style={{ padding: "40px 0 0 0" }}
            >
              <div className="cms-block-container-row row cms-row">
                <div className="col-12">
                  <div className="spized-advanced-usps-element">
                    <div className="spized-advanced-usps-inner block-container">
                      <h2 className="t1">
                        Why You<span style={{ fontFamily: "Arial" }}>'</span>ll
                        Love Our 3D Configurator
                      </h2>
                      <div
                        className="image-text-slider-element cms-element-spized-advanced-usps"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="usp-list-item"
                          style={{ flex: "1 1 calc(33.33% - 20px)", margin: "10px" }}
                        >
                          <div className="usp-list-wrapper">
                            <div className="image-container">
                              <img
                                src="https://www.spized.com/media/f5/cc/e1/1695287230/pfeile.svg"
                                className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
                                width="50"
                                height="50"
                                alt="Endless Customization Options"
                              />
                            </div>
                            <div className="info-block">
                              <p className="title t2">
                                Endless Customization Options
                              </p>
                              <p className="description">
                                Go beyond jerseys! Design shorts, hoodies, tracksuits, and more
                                <span style={{ fontFamily: 'Arial' }}>-</span>all in one place.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="usp-list-item"
                          style={{ flex: "1 1 calc(33.33% - 20px)", margin: "10px" }}
                        >
                          <div className="usp-list-wrapper">
                            <div className="image-container">
                              <img
                                src="https://www.spized.com/media/19/26/7b/1699354877/nachbestellungen-jederzeit-moeglich.svg"
                                className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
                                width="50"
                                height="50"
                                alt="Order Any Quantity—No Minimums!"
                              />
                            </div>
                            <div className="info-block">
                              <p className="title t2">
                                Order Any Quantity<span style={{ fontFamily: 'Arial' }}>-</span>No Minimums!
                              </p>
                              <p className="description">
                                Whether you need one piece or a full team set, we<span style={{ fontFamily: 'Arial' }}>'</span>ve got
                                you covered. Plus, enjoy locked-in pricing on repeat orders.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="usp-list-item"
                          style={{ flex: "1 1 calc(33.33% - 20px)", margin: "10px" }}
                        >
                          <div className="usp-list-wrapper">
                            <div className="image-container">
                              <img
                                src="https://www.spized.com/media/28/d2/6a/1639408457/attraktive-rabatte.svg"
                                className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
                                width="50"
                                height="50"
                                alt="All-Inclusive Pricing"
                              />
                            </div>
                            <div className="info-block">
                              <p className="title t2">All<span>-</span>Inclusive Pricing</p>
                              <p className="description">
                                No hidden fees<span style={{ fontFamily: 'Arial' }}>-</span>printing costs are included, no matter how
                                many design elements you add.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="button-container">
                        <a
                          className="btn button2 usps-button"
                          href="en/benefits.html"
                          style={{
                            backgroundColor: "#000000",
                            color: "#fff",
                            padding: "10px 20px",
                            textDecoration: "none",
                            borderRadius: "5px",
                          }}
                        >
                          Your benefits at a glance
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configurator;