// import React, { useState } from "react";
// import Discover from "../Components/Discover";
// import Footer from "../Components/Footer";
// import Header from "../Components/Header";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addCart,
//   removeCart,
//   updateCartQuantity,
// } from "../../Redux/Cart_System";
// import { useNavigate } from "react-router-dom";
// import { Coupon_data, stockCheck } from "../../Services/Operations/ProductAPI";
// import { toast } from "react-toastify";
// import "../../Styles/Cart.css";

// const CartSec = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [quantity, setQuantity] = useState(0);
//   const [couponCode, setCouponCode] = useState("");
//   const [discountAmount, setDiscountAmount] = useState("");
//   const [couponMessage, setCouponMessage] = useState("");
//   const cartItemsFromRedux = useSelector((state) => state.cart.items);
//   console.log("cartItemsFromRedux : ", cartItemsFromRedux);
//   const { tokenDetails } = useSelector((state) => state.user);
//   const token = tokenDetails;

//   const handleIncrement = async (product) => {
//     console.log("increment product : ",product)
//     const newQuantity = product.quantity + 1;
//     try {
//       const formData = new FormData();
//       formData.append("product_id", product.id);
//       formData.append("size_id", product.size_id);
//       formData.append("quantity", newQuantity);

//        const response = await stockCheck(formData);
//       console.log("API response for product details:", response);

//       if (response.success === true) {
//         setQuantity(newQuantity);
//         console.log("quantity : ", newQuantity);
//         dispatch(updateCartQuantity({ id: product.id, quantity: newQuantity, price: product.price }));
//       } else {
//         toast.error(`Stock not available (Available: ${response.data.stock})`);
//       }
//     } catch (error) {
//       console.error("Error during product API call:", error);
//     }
//   };

//   const handleDecrement = async (product) => {
//     console.log("decrement product : ",product)
//     if (product.quantity > 1) {
//       const newQuantity = product.quantity - 1;
//       try {
//         const formData = new FormData();
//         formData.append("product_id", product.id);
//         formData.append("size_id", product.size_id);
//         formData.append("quantity", newQuantity);

//          const response = await stockCheck(formData);
//         console.log("API response for product details:", response);

//         if (response.success === true) {
//           setQuantity(newQuantity);
//           console.log("quantity : ", newQuantity);
//           dispatch(
//             updateCartQuantity({ id: product.id, quantity: newQuantity, price: product.price })
//           );
//         } else {
//           toast.error(
//             `Stock not available (Available: ${response.data.stock})`
//           );
//         }
//       } catch (error) {
//         console.error("Error during product API call:", error);
//       }
//     } else if (product.quantity === 1) {
//       dispatch(removeCart(product.id));
//       setQuantity(0);
//     }
//   };

//   const removeItem = (id) => {
//     dispatch(removeCart(id));
//     setQuantity(0);
//   };

//   const handleProceedToCheckout = () => {
//     if(!token){
//       navigate("/auth")
//     }
//     else{
//       console.log("payableAmount being passed:", payableAmount);
//     navigate("/checkout", {
//       state: {
//         cartItems: cartItemsFromRedux,
//         totalPrice1: totalPrice,
//         discountAmount: discountAmount,
//         payableAmount: payableAmount,
//         couponCode,
//       },
//     });
//     }
//   };

//   const totalPrice = cartItemsFromRedux.reduce(
//     (total, item) => total + item.price * item.quantity,
//     0
//   );

//   const applyCoupon = async () => {
//     try {
//       const formData = new FormData();
//       formData.append("couponCode", couponCode);
//       formData.append("totalAmount", totalPrice);
//       const result = await Coupon_data(formData, token);
//       if (result.success === true) {
//         setCouponMessage(result.data[0].description);
//         setDiscountAmount(result.data[0].discountAmount);
//         toast.success("Coupon applied successfully");
//       } else {
//         setCouponMessage("Invalid coupon code.");
//         setDiscountAmount(0);
//         toast.error("Invalid coupon code");
//       }
//     } catch (error) {
//       toast.error("please login (token invalid)");
//       console.error("Error applying coupon:", error);
//     }
//   };

//   const payableAmount = discountAmount
//     ? totalPrice - discountAmount
//     : totalPrice;

//   return (
//     <div>
//       <Header />
//       <div
//         style={{
//           padding: "20px",
//           maxWidth: "1280px",
//           margin: "auto",
//           fontFamily: "Open Sans, sans-serif",
//           color: "#333",
//         }}
//       >
//         <h2
//           style={{
//             color: "#333",
//             textAlign: "center",
//             borderBottom: "3px solid #4CAF50",
//             paddingBottom: "10px",
//           }}
//         >
//           Shopping Cart
//         </h2>

//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             gap: "20px",
//             marginTop: "20px",
//             flexWrap: "wrap",
//           }}
//         >
//           {/* Left side: Cart Items */}
//           {cartItemsFromRedux.length === 0 ? (
//             <div
//               style={{
//                 flex: "1",
//                 minWidth: "280px",
//                 backgroundColor: "#f9f9f9",
//                 padding: "15px",
//                 borderRadius: "10px",
//                 boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//                 textAlign: "center",
//               }}
//             >
//               <h3
//                 style={{
//                   color: "#ff5c5c",
//                   fontSize: "1.8em",
//                   fontWeight: "600",
//                 }}
//               >
//                 Your cart is empty
//               </h3>
//               <p
//                 style={{
//                   color: "#555",
//                   fontSize: "1.2em",
//                 }}
//               >
//                 Looks like you haven't added anything to your cart yet.
//               </p>
//             </div>
//           ) : (
//             <div
//               style={{
//                 backgroundColor: "#f9f9f9",
//                 padding: "15px",
//                 borderRadius: "10px",
//                 boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//                 width: "100%",
//               }}
//             >
//               {cartItemsFromRedux.map((item) => {
//                 return (
//                   <div
//                     key={item.id}
//                     style={{
//                       width: "100%", // Use full width for each item
//                       display: "flex",
//                       alignItems: "center",
//                       borderRadius: "8px",
//                       marginBottom: "12px",
//                       flexDirection: "row", // Default to row for larger screens
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <img
//                       src={`{configUrl}${item.gallery[0].image}`}
//                       alt={item.title}
//                       style={{
//                         width: "12%",
//                         height: "118px",
//                         marginRight: "15px",
//                         borderRadius: "8px",
//                         objectFit: "fill",
//                       }}
//                     />
//                     <div
//                       style={{
//                         flex: 1,
//                         width: "auto",
//                         paddingRight: "10px",
//                         width: "55%",
//                       }}
//                     >
//                       <h4
//                         style={{
//                           margin: "0 0 5px",
//                           color: "#333",
//                           fontWeight: "600",
//                           whiteSpace: "nowrap",
//                           overflow: "hidden",
//                           textOverflow: "ellipsis",
//                         }}
//                       >
//                         {item.name}
//                       </h4>
//                       <p style={{ margin: "0", color: "#555" }}>
//                         Price: €{(item.price * item.quantity).toFixed(2)}
//                       </p>

//                       <div
//                         style={{
//                           display: "flex",
//                           alignItems: "center",
//                           marginTop: "8px",
//                         }}
//                       >
//                         <button
//                           onClick={() => handleDecrement(item)}
//                           style={{
//                             backgroundColor: "#e0e0e0",
//                             border: "none",
//                             borderRadius: "50%",
//                             width: "30px",
//                             height: "30px",
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "center",
//                             cursor: "pointer",
//                             marginRight: "5px",
//                             fontWeight: "bold",
//                           }}
//                         >
//                           -
//                         </button>
//                         <span
//                           style={{
//                             margin: "0 12px",
//                             fontSize: "1em",
//                             fontWeight: "500",
//                             color: "#333",
//                           }}
//                         >
//                           {item.quantity}
//                         </span>
//                         <button
//                           onClick={() => handleIncrement(item)}
//                           style={{
//                             backgroundColor: "#e0e0e0",
//                             border: "none",
//                             borderRadius: "50%",
//                             width: "30px",
//                             height: "30px",
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "center",
//                             cursor: "pointer",
//                             marginLeft: "5px",
//                             fontWeight: "bold",
//                           }}
//                         >
//                           +
//                         </button>
//                       </div>
//                     </div>
//                     <button
//                       onClick={() => removeItem(item.id)}
//                       style={{
//                         backgroundColor: "#ff5c5c",
//                         color: "#fff",
//                         border: "none",
//                         padding: "8px 12px",
//                         cursor: "pointer",
//                         borderRadius: "6px",
//                         fontWeight: "bold",
//                         marginLeft: "10px",
//                         width: "fit-content",
//                         marginTop: "10px", // Ensure it's aligned properly
//                       }}
//                     >
//                       Remove
//                     </button>
//                   </div>
//                 );
//               })}
//             </div>
//           )}
//           {cartItemsFromRedux.length !== 0 ? (
//             <div
//               style={{
//                 width: "100%",
//                 margin: "20px auto",
//                 padding: "20px",
//                 backgroundColor: "#f9f9f9",
//                 borderRadius: "10px",
//                 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 fontFamily: "Arial, sans-serif",
//               }}
//             >
//               <form
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "100%",
//                     marginBottom: "15px",
//                     textAlign: "center",
//                   }}
//                 >
//                   <h3 style={{ margin: "0", fontSize: "18px", color: "#333" }}>
//                     Apply Coupon Code
//                   </h3>
//                   <p
//                     style={{ margin: "5px 0", fontSize: "14px", color: "#777" }}
//                   >
//                     Enter your coupon code below to avail discounts.
//                   </p>
//                 </div>
//                 <div
//                   style={{
//                     display: "flex",
//                     width: "100%",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     gap: "10px",
//                   }}
//                 >
//                   <input
//                     type="text"
//                     placeholder="Enter Coupon Code"
//                     value={couponCode}
//                     onChange={(e) => setCouponCode(e.target.value)}
//                     style={{
//                       flex: "1",
//                       padding: "10px",
//                       borderRadius: "5px",
//                       border: "1px solid #ccc",
//                       fontSize: "14px",
//                       color: "#555",
//                       boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.05)",
//                     }}
//                   />
//                   <button
//                     type="button"
//                     onClick={applyCoupon}
//                     style={{
//                       padding: "10px 20px",
//                       backgroundColor: "rgb(76, 175, 80)",
//                       color: "#fff",
//                       border: "none",
//                       borderRadius: "5px",
//                       cursor: "pointer",
//                       fontSize: "14px",
//                       fontWeight: "bold",
//                       transition: "background-color 0.3s ease",
//                     }}
//                     onMouseEnter={(e) =>
//                       (e.target.style.backgroundColor = "rgb(36, 111,39)")
//                     }
//                     onMouseLeave={(e) =>
//                       (e.target.style.backgroundColor = "rgb(76, 175, 80)")
//                     }
//                   >
//                     Apply Coupon
//                   </button>
//                 </div>
//               </form>
//             </div>
//           ) : (
//             ""
//           )}
//           {cartItemsFromRedux.length !== 0 ? (
//             <div
//               style={{
//                 width: "100%",
//                 padding: "20px",
//                 backgroundColor: "#f9f9f9",
//                 borderRadius: "10px",
//                 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 fontFamily: "Arial, sans-serif",
//               }}
//             >
//               <p
//                 style={{
//                   fontSize: "16px",
//                   fontWeight: "bold",
//                   marginBottom: "10px",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   color: "#333",
//                 }}
//               >
//                 Total Price
//                 <span style={{ color: "#007bff" }}>
//                   {totalPrice.toFixed(2)}
//                 </span>
//               </p>
//               <p
//                 style={{
//                   fontSize: "14px",
//                   marginBottom: "10px",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   color: "#555",
//                 }}
//               >
//                 Discount Amount
//                 <span style={{ color: discountAmount ? "#ff4d4f" : "#aaa" }}>
//                   {discountAmount ? `- ${discountAmount}` : 0}
//                 </span>
//               </p>
//               <p
//                 style={{
//                   fontSize: "16px",
//                   fontWeight: "bold",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   color: "#333",
//                   borderTop: "1px solid #ddd",
//                   paddingTop: "10px",
//                 }}
//               >
//                 Payable Amount {discountAmount ? "(5% discount)" : ""}
//                 <span style={{ color: "#28a745" }}>
//                   {payableAmount.toFixed(2)}
//                 </span>
//               </p>
//             </div>
//           ) : (
//             ""
//           )}

//           {cartItemsFromRedux.length === 0 ? (
//             ""
//           ) : (
//             <button
//               style={{
//                 marginTop: "20px",
//                 padding: "14px",
//                 width: "100%",
//                 backgroundColor: "#4CAF50",
//                 color: "#fff",
//                 border: "none",
//                 borderRadius: "8px",
//                 cursor: "pointer",
//                 fontSize: "1.1em",
//                 fontWeight: "bold",
//               }}
//               disabled={cartItemsFromRedux.length === 0}
//               onClick={handleProceedToCheckout}
//             >
//               Proceed to Checkout
//             </button>
//           )}
//         </div>
//       </div>
//       <Discover />
//       <Footer />
//     </div>
//   );
// };

// export default CartSec;

import React, { useState } from "react";
import { FaCirclePlus } from "react-icons/fa6";
import Discover from "../Components/Discover";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  removeCart,
  updateCartItem,
  updateCartQuantity,
  updateCartSize,
} from "../../Redux/Cart_System";
import { useNavigate } from "react-router-dom";
import {
  Coupon_data,
  productDetails,
  stockCheck,
} from "../../Services/Operations/ProductAPI";
import { toast } from "react-toastify";
import "../../Styles/Cart.css";

const CartSec = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [sizeAll, setSize] = useState([]);
  const [sizeId, setSizeId] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const cartItemsFromRedux = useSelector((state) => state.cart.items);
  console.log("cartItemsFromRedux : ", cartItemsFromRedux);
  const { tokenDetails } = useSelector((state) => state.user);
  const { configUrl } = useSelector((state) => state.cart);
  console.log("configUrl : ", configUrl);
  const token = tokenDetails;
  const handleInputChange = (itemId, sizeIndex, field, value) => {
    console.log("itemId : ", itemId);
    console.log("sizeIndex : ", sizeIndex);
    console.log("field : ", field);
    console.log("value : ", value);
    dispatch(updateCartItem({ itemId, sizeIndex, field, value }));
  };

  const handleIncrement = async (product, rowIndex) => {
    console.log("Increment product: ", product);
    console.log("rowIndex: ", rowIndex);

    const updatedSizes = [...product.sizes];

    if (!updatedSizes[rowIndex]) return;

    const newQuantity = updatedSizes[rowIndex].quantity + 1;
    console.log(
      "updatedSizes[rowIndex].quantity + 1 : ",
      updatedSizes[rowIndex].quantity + 1
    );

    try {
      const formData = new FormData();
      formData.append("product_id", product.id);
      formData.append("size_id", updatedSizes[rowIndex].size_id);
      formData.append("quantity", newQuantity);

      const response = await stockCheck(formData);
      console.log("API response: ", response);

      if (response.success) {
        console.log("Don");
        console.log("newQuantity : ", newQuantity);
        console.log(
          "updatedSizes[rowIndex].quantity : ",
          updatedSizes[rowIndex].quantity
        );
        console.log("updatedSizes : ", updatedSizes);
        console.log(
          "updatedSizes[rowIndex].price : ",
          updatedSizes[rowIndex].price
        );

        dispatch(
          updateCartQuantity({
            id: product.id,
            rowIndex,
            quantity: newQuantity,
            price: updatedSizes[rowIndex].price,
          })
        );
        console.log("don");
      } else {
        toast.error(`Stock not available (Available: ${response.data.stock})`);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleSizeChange = (e, item, rowIndex) => {
    const newSizeId = e.target.value;
    console.log("Selected size ID:", newSizeId);

    dispatch(updateCartSize({ productId: item.id, rowIndex, newSizeId }));
  };

  const handleDecrement = async (product, rowIndex) => {
    console.log("Decrement product: ", product);

    const updatedSizes = [...product.sizes];

    if (!updatedSizes[rowIndex]) return;

    const newQuantity = updatedSizes[rowIndex].quantity - 1;

    if (newQuantity > 0) {
      try {
        const formData = new FormData();
        formData.append("product_id", product.id);
        formData.append("size_id", updatedSizes[rowIndex].size_id);
        formData.append("quantity", newQuantity);

        const response = await stockCheck(formData);
        console.log("API response: ", response);
        if (response.success) {
          dispatch(
            updateCartQuantity({
              id: product.id,
              rowIndex,
              quantity: newQuantity,
              price: updatedSizes[rowIndex].price,
            })
          );
        } else {
          toast.error(
            `Stock not available (Available: ${response.data.stock})`
          );
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    } else {
      dispatch(removeCart({ productId: product.id, rowIndex: rowIndex }));
    }
  };

  const removeItem = (id, rowIndex) => {
    dispatch(removeCart({ productId: id, rowIndex }));
  };

  const handleProceedToCheckout = () => {
    if (!token) {
      navigate("/auth");
    } else {
      console.log("payableAmount being passed:", payableAmount);
      navigate("/checkout", {
        state: {
          cartItems: cartItemsFromRedux,
          totalPrice1: totalPrice,
          discountAmount: discountAmount,
          payableAmount: payableAmount,
          couponCode,
        },
      });
    }
  };

  const totalPrice = cartItemsFromRedux.reduce((total, item) => {
    const itemTotalPrice = item.sizes.reduce(
      (sizeTotal, size) => sizeTotal + size.price * size.quantity,
      0
    );

    return total + itemTotalPrice;
  }, 0);

  console.log("Total Price:", totalPrice);

  const applyCoupon = async () => {
    try {
      const formData = new FormData();
      console.log("couponCode : ",couponCode);
      console.log("totalPrice : ",totalPrice);
      formData.append("couponCode", couponCode);
      formData.append("totalAmount", totalPrice);
      const result = await Coupon_data(formData, token);
      if (result.success === true) {
        setCouponMessage(result.data[0].description);
        setDiscountAmount(result.data[0].discountAmount);
        toast.success("Coupon applied successfully");
      } else {
        setCouponMessage("Invalid coupon code.");
        setDiscountAmount(0);
        toast.error("Invalid coupon code");
      }
    } catch (error) {
      toast.error("please login (token invalid)");
      console.error("Error applying coupon:", error);
    }
  };

  const payableAmount = discountAmount
    ? totalPrice - discountAmount
    : totalPrice;

  const [cartRows, setCartRows] = useState(
    cartItemsFromRedux.reduce((acc, item) => {
      acc[item.id] = [
        { id: 1, name: "", size: "", jerseyNumber: "", quantity: 1 },
      ];
      return acc;
    }, {})
  );

  const sizeOptions = ["S", "M", "L", "XL"];

  const addRow = async (tableId, table) => {
    console.log("table : ", table);
    const defaultSize = table.sizes?.length > 0 ? table.sizes[0].size_id : 0;

    console.log("defaultSize : ", defaultSize);

    setCartRows((prevRows) => ({
      ...prevRows,
      [tableId]: [
        ...(prevRows[tableId] || []),
        {
          id: (prevRows[tableId]?.length || 0) + 1,
          name: table.name,
          size: defaultSize,
          jerseyNumber: "",
          quantity: 1,
        },
      ],
    }));

    try {
      const formData = new FormData();
      formData.append("product_id", table.id);
      formData.append("size_id", defaultSize);
      formData.append("quantity", 1);

      const response = await stockCheck(formData);
      console.log("API response for product details:", response);

      if (response.success === true) {
        console.log("don........");
        dispatch(
          addCart({
            id: table.id,
            name: table.name,
            price:
              table.sizes.find((s) => s.size_id === defaultSize)?.price || 0,
            quantity: 1,
            size_id: defaultSize,
          })
        );
        setQuantity(1);
        console.log("don........");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error during product API call:", error);
    }
  };

  // const updateRow = (id, field, value) => {
  //   const updatedRows = rows.map((row) =>
  //     row.id === id ? { ...row, [field]: value } : row
  //   );
  //   setRows(updatedRows);
  // };

  // const updateQuantity = (id, increment) => {
  //   const updatedRows = rows.map((row) =>
  //     row.id === id
  //       ? { ...row, quantity: Math.max(1, row.quantity + increment) }
  //       : row
  //   );
  //   setRows(updatedRows);
  // };

  const clickProductDetailsHandler = async (name) => {
    try {
      console.log("productSlug:", name);
      const formData = new FormData();
      formData.append("slug", name);

      const response = await productDetails(formData);
      console.log("API response for product details:", response);

      if (response.success === true) {
        setSize(response.data.size);
      }
    } catch (error) {
      console.error("Error during product API call:", error);
    }
  };

  return (
    <>
      <Header />
      {cartItemsFromRedux.length === 0 ? (
        <div
          style={{
            flex: "1",
            minWidth: "280px",
            backgroundColor: "#f9f9f9",
            padding: "15px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            textAlign: "center",
            height: "89.7vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3
            style={{
              color: "#ff5c5c",
              fontSize: "1.8em",
              fontWeight: "600",
            }}
          >
            Your cart is empty
          </h3>
          <p
            style={{
              color: "#555",
              fontSize: "1.2em",
            }}
          >
            Looks like you haven't added anything to your cart yet.
          </p>
        </div>
      ) : (
        <div className="max-w-5xl mx-auto p-6">
          <h1 className="text-2xl font-bold mb-6 text-center">Cart Section</h1>
          <div className="table-wrapper">
            <div
              className="table-scroll"
              style={{ gap: "30px", display: "flex", flexDirection: "column" }}
            >
              {cartItemsFromRedux.map((item) => (
                <div key={item.id}>
                  <h2 style={{ margin: "10px 20px", paddingTop: "20px" }}>
                    {item.name}
                  </h2>
                  <table className="table-auto w-full border border-gray-300 m-auto">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2">#</th>
                        <th className="border border-gray-300 px-4 py-2">
                          Name
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Number
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Size
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Quantity
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.sizes.map((size, rowIndex) => (
                        <tr key={rowIndex} className="hover:bg-gray-50">
                          <td className="border border-gray-300 px-4 py-2">
                            {rowIndex + 1}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            <input
                              type="text"
                              placeholder="Enter name"
                              value={size.name || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  rowIndex,
                                  "name",
                                  e.target.value
                                )
                              }
                              className="w-full px-3 py-2 bg-white text-black border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                            />
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            <input
                              type="number"
                              placeholder="Enter jersey number"
                              value={size.jerseyNumber || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  rowIndex,
                                  "jerseyNumber",
                                  e.target.value
                                )
                              }
                              className="w-full px-3 py-2 bg-white text-black border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                            />
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            <select
                              className="w-full px-3 py-2 bg-transparent text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                              value={size.size || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  rowIndex,
                                  "size",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Size</option>
                              {sizeAll?.map((sizeOption, index) => (
                                <option key={index} value={sizeOption.name}>
                                  {sizeOption.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            <div className="flex items-center gap-2 justify-center">
                              <button
                                onClick={() => handleDecrement(item, rowIndex)}
                                style={{ backgroundColor: "#003366" }}
                                className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                              >
                                -
                              </button>
                              <span className="text-gray-700">
                                {size.quantity}
                              </span>
                              <button
                                onClick={() => handleIncrement(item, rowIndex)}
                                style={{ backgroundColor: "#003366" }}
                                className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                              >
                                +
                              </button>
                            </div>
                          </td>
                          <td className="border border-gray-300 px-4 py-2 ">
                            <button
                              onClick={() => removeItem(item.id, rowIndex)}
                              className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                              style={{ backgroundColor: "#003366" }}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ position: "relative" }}>
                    <button
                      onClick={() => addRow(item.id, item)}
                      style={{
                        width: "49px",
                        height: "51px",
                        fontSize: "24px",
                        borderRadius: "50%",
                        position: "absolute",
                        right: "23px",
                        bottom: "-56px",
                      }}
                      className="button-style p-2 bg-blue-500 text-white shadow-md hover:bg-blue-600 transition"
                    >
                      <FaCirclePlus />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {cartItemsFromRedux.length !== 0 ? (
            <div
              style={{
                width: "100%",
                margin: "20px auto",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                // fontFamily: "Arial, sans-serif",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginBottom: "15px",
                    textAlign: "center",
                  }}
                >
                  <h3 style={{ margin: "0", fontSize: "18px", color: "#333" }}>
                    Apply Coupon Code
                  </h3>
                  <p
                    style={{ margin: "5px 0", fontSize: "14px", color: "#777" }}
                  >
                    Enter your coupon code below to avail discounts.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Coupon Code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    style={{
                      flex: "1",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      fontSize: "14px",
                      color: "#555",
                      boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                  <button
                    type="button"
                    onClick={applyCoupon}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "rgb(76, 175, 80)",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "bold",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "rgb(36, 111,39)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "rgb(76, 175, 80)")
                    }
                  >
                    Apply Coupon
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
          {cartItemsFromRedux.length !== 0 ? (
            <div
              style={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                // fontFamily: "Arial, sans-serif",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#333",
                }}
              >
                Total Price
                <span style={{ color: "#007bff" }}>
                  {totalPrice.toFixed(2)}
                </span>
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#555",
                }}
              >
                Discount Amount
                <span style={{ color: discountAmount ? "#ff4d4f" : "#aaa" }}>
                  {discountAmount ? `- ${discountAmount}` : 0}
                </span>
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#333",
                  borderTop: "1px solid #ddd",
                  paddingTop: "10px",
                }}
              >
                Payable Amount {discountAmount ? "(5% discount)" : ""}
                <span style={{ color: "#28a745" }}>
                  {payableAmount.toFixed(2)}
                </span>
              </p>
            </div>
          ) : (
            ""
          )}

          {cartItemsFromRedux.length === 0 ? (
            ""
          ) : (
            <button
              style={{
                marginTop: "20px",
                padding: "14px",
                width: "100%",
                backgroundColor: "#4CAF50",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "1.1em",
                fontWeight: "bold",
              }}
              disabled={cartItemsFromRedux.length === 0}
              onClick={handleProceedToCheckout}
            >
              Proceed to Checkout
            </button>
          )}
          <Discover />
          <Footer />
        </div>
      )}
    </>
  );
};

export default CartSec;
