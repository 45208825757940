import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";

function SimilarProducts({ product }) {
  const sliderRef = useRef(null);
  console.log("SimilarProducts : ", product);
  const { configUrl } = useSelector((state) => state.cart);
  console.log("configUrl : ",configUrl)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrev = () => {
    console.log("Prev Button Clicked");
    if (sliderRef.current) {
      console.log("Calling slickPrev");
      console.log(sliderRef.current);
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    console.log("Next Button Clicked");
    if (sliderRef.current) {
      console.log("Calling slickNext");
      console.log(sliderRef.current);
      sliderRef.current.slickNext();
    }
  };

  return (
    <div
      style={{
        position: "relative",
        padding: "20px",
        maxWidth: "100%",
        margin: "0 auto",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Similar Products</h2>
      {/* <Slider {...settings} ref={sliderRef}>
        {products.map((product) => (
          <div key={product.id} style={{ padding: '10px' }}>
            <div style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', boxSizing: 'border-box' }}>
              <div style={{ position: 'relative', width: '100%', height: '0', paddingBottom: '100%' }}>
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </div>
              <h3 style={{ fontSize: '1.1rem', margin: '10px 0' }}>{product.name}</h3>
              <p style={{ margin: '0', color: '#555' }}>Price for 10 pcs.: {product.price}</p>
              <p style={{ margin: '0', color: '#555' }}>Unit price: {product.unitPrice}</p>
            </div>
          </div>
        ))}
      </Slider> */}
      {product.similarProduct && product.similarProduct.length > 0 && (
        <Slider {...settings} ref={sliderRef}>
          {product.similarProduct.map((product) => (
            <div key={product.id} style={{ padding: "10px" }}>
              <div
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "16px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "0",
                    paddingBottom: "100%",
                  }}
                >
                  <img
                    src={`${configUrl}${product.image}`}
                    alt={product.name}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </div>
                <h3
                  style={{
                    fontSize: "1.1rem",
                    margin: "10px 0",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {product.name}
                </h3>
                <p style={{ margin: "0", color: "#555" }}>
                  Unit price: {product.size.price}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      )}
      <div style={buttonContainerStyle}>
        <div onClick={handlePrev} style={iconStyle}>
          <FaChevronLeft />
        </div>
        <div onClick={handleNext} style={iconStyle}>
          <FaChevronRight />
        </div>
      </div>
    </div>
  );
}

const iconStyle = {
  fontSize: "24px",
  color: "#007bff",
  cursor: "pointer",
  padding: "10px",
  background: "white",
  borderRadius: "50%",
  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
};

const buttonContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

export default SimilarProducts;
